import React, { createContext, useEffect, useState } from 'react';
import { AppState } from "../model/app_state";

interface AppContextProps {
    appState: AppState
    rememberMeChecked: (shortUserSession: boolean) => void;
}

export const AppContext = createContext<AppContextProps | null>(null);

const AppProvider: React.FC<any> = ({children}) => {
    const [appState, setAppState] = useState<AppState>({
        rememberMeChecked: false
    });

    useEffect(() => {
        const storedState = sessionStorage.getItem('appState');
        if (storedState) {
            setAppState(JSON.parse(storedState));
        }
    }, []);

    useEffect(() => {
        console.log('appState: ', appState)
        sessionStorage.setItem('appState', JSON.stringify(appState));
    }, [appState]);

    const rememberMeChecked = (rememberMeChecked: boolean) => {
        setAppState((prevState) => ({
            ...prevState,
            rememberMeChecked: rememberMeChecked,
        }));
    }

    return (
        <AppContext.Provider value={{appState, rememberMeChecked}}>
            {children}
        </AppContext.Provider>
    );
}

export default AppProvider;
