import { Button, Card, Checkbox, Form, Input, Modal, Spin } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RegisterProvider from "../../network/register/RegisterProvider";
import RegisterFormValues from "../../model/register/RegisterFormValues";
import AuthRequest from "../../model/login/AuthRequest";
import LoginProvider from "../../network/login/LoginProvider";
import { useNavigate } from "react-router-dom";
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { AxiosError } from "axios";
import ApiErrorResponse from "../../model/error/ApiErrorResponse";
import useAnalyticsEventTracker, { gaPageViewTracker } from "../../utils/UseAnalyticsEventTracker";

const RegisterView: React.FC = () => {
    gaPageViewTracker('register')
    const gaEventTracker = useAnalyticsEventTracker('RegisterForm')
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const [form] = Form.useForm();
    const [modal, setModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const navigate = useNavigate()
    const signIn = useSignIn()

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 3,
            },
            sm: {
                span: 16,
                offset: 4,
            },
        },
    };
    const tailFormButtonLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 3,
            },
            sm: {
                span: 16,
                offset: 10,
            },
        },
    };

    const onFinish = async (values: RegisterFormValues) => {
        setLoading(true)
        try {
            gaEventTracker('register')
            await RegisterProvider(values)
            const authRequest: AuthRequest = {
                email: values.email!!,
                password: values.password!!,
            }
            const response = await LoginProvider(authRequest)
            const accessToken = response.accessToken
            const refreshToken = response.refreshToken
            const signInResult = signIn({
                auth: {
                    token: accessToken,
                    type: 'Bearer',
                },
                refresh: refreshToken,
            })
            if (signInResult) {
                navigate('/')
            } else {
                setModal(true)
            }
            setLoading(false)
        } catch (e: any) {
            // check if e is AxiosError
            setErrorMessage(t('registerErrorMessage'))
            if (e.isAxiosError) {
                const axiosError = e as AxiosError<ApiErrorResponse>
                const errorCode = axiosError.response?.data.error?.errorCode
                if (errorCode == 'USER_ALREADY_EXISTS') {
                    setErrorMessage(t('registerErrorMessageUserExists'))
                }
            }
            console.log(e)
            setModal(true)
            setLoading(false)
        }

    };

    return (
        <div
            style={{
                background: '#ececec',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}>

            <Spin spinning={loading} fullscreen/>
            <Modal
                open={modal}
                centered={true}
                footer={[
                    <Button key="submit" type="primary" onClick={() => {
                        setModal(false)
                    }}>
                        OK
                    </Button>,
                ]}
                title={t('registerErrorTitle')}>
                <span>{errorMessage}</span>
            </Modal>
            <Card
                title={t('registerTitle')}
                style={{
                    width: '600px',
                    textAlign: 'center',
                }}>
                <div style={{
                    marginBottom: '40px',
                }}>
                    <img src="/logo.png" alt="AgroArchitect logo" className="logo-image"/>
                </div>

                <Form
                    form={form}
                    style={{
                        textAlign: 'left',
                    }}
                    labelCol={{span: 5}}
                    wrapperCol={{span: 16}}
                    name="register"
                    initialValues={{remember: true}}
                    onFinish={onFinish}>
                    <Form.Item<RegisterFormValues>
                        label={t('registerFieldFirstName')}
                        name="firstName"
                        hasFeedback
                        rules={[{required: true, message: t('registerFieldFirstNameRequired')}]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item<RegisterFormValues>
                        style={{marginTop: '40px'}}
                        label={t('registerFieldLastName')}
                        name="lastName"
                        hasFeedback
                        rules={[{required: true, message: t('registerFieldLastNameRequired')}]}>

                        <Input/>
                    </Form.Item>

                    <Form.Item<RegisterFormValues>
                        style={{marginTop: '40px'}}
                        label={t('registerFieldEmail')}
                        name="email"
                        hasFeedback
                        rules={[
                            {required: true, message: t('registerFieldEmailRequired')},
                            {type: 'email', message: t('registerFieldEmailIncorrect')}
                        ]}>
                        <Input type="email"/>
                    </Form.Item>

                    <Form.Item<RegisterFormValues>
                        style={{marginTop: '40px'}}
                        label={t('registerFieldPassword')}
                        name="password"
                        hasFeedback
                        rules={[
                            {min: 8, message: t('registerFieldPasswordMinLength')},
                            {pattern: new RegExp('(?=.*\\d)'), message: t('registerFieldPasswordMinOneDigit')},
                            {required: true, message: t('registerFieldPasswordRequired')}
                        ]}>
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item<RegisterFormValues>
                        style={{marginTop: '40px'}}
                        label={t('registerFieldPasswordRepeat')}
                        dependencies={['password']}
                        hasFeedback
                        name="confirmPassword"
                        rules={[
                            {required: true, message: t('registerFieldPasswordRequired')},
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('registerFieldPasswordRepeatDoNotMatch')));
                                },
                            })
                        ]}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item<RegisterFormValues>
                        name="agreementsChecked"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error(t('registerTCandPPRequired'))),
                            },
                        ]}
                        {...tailFormItemLayout}
                    >
                        <Checkbox>
                            {t('registerTermsAndPrivacy1')}
                            <a href="https://agroarchitect.com/?page_id=2">{t('registerTermsAndConditions')}</a>
                            {t('registerTermsAndPrivacy2')}
                            <a href="https://agroarchitect.com/?page_id=3">{t('registerPrivacyPolicy')}</a>
                            {t('registerTermsAndPrivacy3')}
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        style={{marginTop: '40px'}}
                        {...tailFormButtonLayout}>
                        <Button type="primary" htmlType="submit">
                            {t('registerButton')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

export default RegisterView
