import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    DeleteOutlined,
    EditOutlined,
    PrinterOutlined,
    WarningFilled
} from "@ant-design/icons";
import { Header } from "antd/es/layout/layout";
import { Button, Empty, message, Modal, Select, Spin, Table } from "antd";
import Field from "../../model/field/field";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import AgroMapView from "../map/AgroMapView";
import AgroMapProvider from "../map/AgroMapProvider";
import { useTranslation } from "react-i18next";
import TitleItemView from "../views/TitleItemView";
import getAreaInHa from "../../utils/FieldUtils";
import Paragraph from "antd/lib/typography/Paragraph";
import FieldHistoryEntry from "../../model/field/FieldHistoryEntry";
import GetFieldHistoryProvider from "../../network/field/GetFieldHistoryProvider";
import { useFieldsManager } from "../../utils/field/FieldProvider";
import { FieldAction } from "../../model/field/FieldAction";
import {
    getHistoryFieldAction,
    getHistoryFieldAllActionColumns,
    getHistoryFieldAllActionData,
    getHistoryFieldCropActionColumns,
    getHistoryFieldCropActionData,
    getHistoryFieldFertilizingActionColumns,
    getHistoryFieldSprayingActionColumns
} from "./FieldHistoryColumns";
import dayjs from "dayjs";
import DeleteFieldProvider from "../../network/field/DeleteFieldProvider";
import "./field_details.css"

const FieldDetailsHeaderView: React.FC = () => {
    const navigate = useNavigate()
    const {id} = useParams();
    const {t} = useTranslation()
    const fieldsManager = useFieldsManager()
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false)
    const authHeader = useAuthHeader()!
    const [messageApi, messageContextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const deleteField = async () => {
        try {
            setIsLoading(true)
            await DeleteFieldProvider(authHeader, id!)
            fieldsManager.requestFieldsUpdate
            setIsLoading(false)
            navigate(-1)
        } catch (e) {
            messageApi.error(t('errorDeleteField'))
            console.log(e)
        }
    }

    const selectPrevField = () => {
        navigate('/field/' + fieldsManager.getPrevFieldId(id!))
    }

    const selectNextField = () => {
        navigate('/field/' + fieldsManager.getNextFieldId(id!))
    }

    return <div>
        <Modal
            title={<div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                }}>
                <WarningFilled style={{
                    color: '#ff0000',
                    fontSize: 30,
                    marginRight: 10
                }}/><p>{t('deleteFieldModalTitle')}</p></div>}
            open={isDeleteModalVisible}
            okText={t('yes')}
            cancelText={t('no')}
            okButtonProps={{danger: true}}
            closable={true}
            onCancel={() => {
                setIsDeleteModalVisible(false)
            }}
            onOk={() => {
                deleteField()
                setIsDeleteModalVisible(false)
            }}
        ><Paragraph>{t('deleteFieldModalMessage')}</Paragraph></Modal>

        <Spin spinning={isLoading} fullscreen/>

        <Header
            style={{
                paddingRight: '10px',
                paddingLeft: '10px',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'white',
                borderBottom: '1px solid #f0f0f0',
            }}
        >

            <div style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
            }}>
                <ArrowLeftOutlined
                    style={{
                        fontSize: '20px',
                        padding: '10px',
                    }}
                    onClick={() => navigate(-1)}/>
            </div>

            <div style={{
                width: '100%',
            }}>
                <Button
                    style={{
                        marginRight: 10
                    }}
                    type={"primary"}
                    icon={<ArrowLeftOutlined/>}
                    onClick={selectPrevField}
                ><span id="headerButtonText">{t('previousField')}</span></Button>
                <Button
                    type={"primary"}
                    icon={<ArrowRightOutlined/>}
                    onClick={selectNextField}
                ><span id="headerButtonText">{t('nextField')}</span></Button>
            </div>

            <div>

                <Button id="deleteButton"
                        icon={<DeleteOutlined/>}
                        type="primary"
                        onClick={() => {
                            setIsDeleteModalVisible(true)
                        }}
                        danger
                ><span id="headerButtonText">{t('deleteField')}</span></Button>
                {/*<Button style={{
                    marginRight: 10
                }}
                        icon={<PrinterOutlined/>}
                        type="primary">Drukuj</Button>
                <Button style={{
                    marginRight: 10
                }}
                        icon={<EditOutlined/>}
                        type="primary">Edytuj działkę</Button>*/}

            </div>
        </Header>
    </div>
}

interface FieldHistoryProps {
    fieldId: string
}

const FieldHistoryView: React.FC<FieldHistoryProps> = ({fieldId}) => {
    const authHeader = useAuthHeader()!
    const {t} = useTranslation()
    const [historyEntries, setHistoryEntries] = useState<FieldHistoryEntry[]>([])
    const [columns, setColumns] = useState<any[]>(getHistoryFieldAllActionColumns(t))
    const [data, setData] = useState<any[]>(getHistoryFieldAllActionData(t, historyEntries))
    const [selectedAction, setSelectedAction] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const fetchHistory = async () => {
        try {
            setIsLoading(true)
            const historyEntries = await GetFieldHistoryProvider(authHeader, fieldId, selectedAction)
            const sortedHistoryEntries = historyEntries.sort((first, second) => dayjs(second.date).diff(dayjs(first.date)))

            setHistoryEntries(sortedHistoryEntries)
            setData(getHistoryFieldAllActionData(t, sortedHistoryEntries))
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchHistory()
    }, [])

    useEffect(() => {
        fetchHistory()
    }, [selectedAction])

    const onSelectedActionChanged: (action: string | null) => void = (action) => {
        console.log(action)
        if (action === "ALL") {
            setSelectedAction(null)
            setColumns(getHistoryFieldAllActionColumns(t))
        } else {
            setSelectedAction(action)
            if (action !== null) {
                const fieldAction = Object.values(FieldAction).find((fieldAction) => fieldAction === action)
                switch (action) {
                    case FieldAction.SOWING_PLANTING:
                        setColumns(getHistoryFieldCropActionColumns(t))
                        setData(getHistoryFieldCropActionData(t, historyEntries))
                        break
                    case FieldAction.FERTILIZING:
                        setColumns(getHistoryFieldFertilizingActionColumns(t))
                        break
                    case FieldAction.SPRAYING:
                        setColumns(getHistoryFieldSprayingActionColumns(t))
                        break
                    default:
                        setColumns(getHistoryFieldAllActionColumns(t))
                }
            }
        }
    }

    const header = <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}
    >
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',

        }}>
            <Paragraph style={{
                margin: 0,
                marginLeft: 20,
                marginRight: 30
            }}>{t('fieldActionTypeSelect')}</Paragraph>
            <Select
                style={{
                    width: 200,
                }}
                onChange={onSelectedActionChanged}
                defaultValue={"ALL"}
                options={getHistoryFieldAction(t)}/>
        </div>
        <Paragraph style={{
            margin: 0,
            fontSize: 20
        }}>{t('fieldHistory')}</Paragraph>

        <div style={{width: 400}}/>
    </div>

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        }}
    >
        <Table
            style={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                padding: 20
            }}
            columns={columns}
            dataSource={data}
            loading={isLoading}
            title={() => header}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t('fieldActionHistoryEmpty')}
                    />
                ),
            }}
            pagination={{
                pageSize: 50
            }}
            bordered
        />
    </div>
}

interface FieldParamsProps {
    field: Field
}

const FieldParamsView: React.FC<FieldParamsProps> = ({field}) => {
    const {t} = useTranslation()
    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        }}
    >
        <div
            id="fieldDetailsContent"
        >

            <TitleItemView
                title={t('fieldName')}
                label={field.fieldName}
                useMarginTop={false}
                fontSize={18}
            />
            <TitleItemView
                title={t('fieldRegistrationNumber')}
                label={field.registrationNumber}
                fontSize={18}
            />
            <TitleItemView
                title={t('fieldMapPopupArea')}
                label={getAreaInHa(field.area, true, t)}
                fontSize={18}
            />
            <TitleItemView
                title={t('fieldMapPopupMKOArea')}
                label={getAreaInHa(field.mkoArea, true, t)}
                fontSize={18}
            />
            {field.crop !== null && <TitleItemView
                title={t('currentCropOnField')}
                label={field.crop!.name}
                fontSize={18}
            />}
        </div>
    </div>
}

const FieldDetailsView: React.FC = () => {
    const {id} = useParams();
    const [isLoading, setIsLoading] = useState<Boolean>(false)
    const fieldsManager = useFieldsManager()
    const [field, setField] = useState<Field | null>(null)
    const mapDivRef = useRef();
    const [size, setSize] = useState({width: 0, height: 0});

    setTimeout(() => {
        setField(fieldsManager.getFieldById(id!))
    }, 100)
    useEffect(() => {
        if (fieldsManager.getFieldById(id!) === null) {
            setIsLoading(true)
            fieldsManager.requestFieldsUpdate()
        }
    }, []);

    useEffect(() => {
        setIsLoading(false)
    }, [fieldsManager.fields]);
    useEffect(() => {
        if (mapDivRef.current) {
            // @ts-ignore
            const width = mapDivRef.current.offsetWidth;
            // @ts-ignore
            setSize({width: '60%', height: width * 0.6}); // 80% of the parent width
        }
        // @ts-ignore
    }, [mapDivRef.current?.offsetWidth]);

    return <div
        style={{
            position: 'relative',
            display: 'flex',
            width: '100%',
            height: '100%'
        }}>
        {isLoading && <Spin
            style={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                zIndex: 100,
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
            }}
        />}
        <div style={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            overflow: 'auto'
        }}>

            <FieldDetailsHeaderView/>
            <AgroMapProvider>
                <div>
                    <div
                        id="layout">

                        <div
                            id="fieldContent"
                        >
                            {field !== null && <FieldParamsView field={field}/>}
                        </div>
                        <div
                            id="mapContent"
                            // @ts-ignore
                            ref={mapDivRef}
                        >
                            {field !== null &&
                                <div
                                    style={{
                                        display: 'flex',
                                        width: size.width,
                                        height: size.height,
                                    }}
                                >
                                    <AgroMapView fields={[field]}/></div>
                            }
                        </div>
                    </div>
                </div>
            </AgroMapProvider>
            <FieldHistoryView fieldId={id!}/>
        </div>
    </div>
}

export default FieldDetailsView
