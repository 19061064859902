import React from 'react'
import './App.css'
import './colors.css'
import AuthProvider from "react-auth-kit";
import { store } from "./auth.js";
import { I18nextProvider } from "react-i18next";
import i18 from "./i18n/config";
import AppRouter from "./routes";
import ReactGa from "react-ga4";

const TRACKING_ID = "G-83XPTEH4SN"
ReactGa.initialize(TRACKING_ID)

function App() {
    return (
        <div className="App">
            <AuthProvider store={store}>
                <I18nextProvider i18n={i18}>
                    <AppRouter/>
                </I18nextProvider>
            </AuthProvider>
        </div>
    )
}

export default App
