import apiClient from "./network_provider";
import StaticMapRequest from "../model/map/StaticMapRequest";

interface StaticMapResponse {
    id: string
}

async function GenerateStaticMapProvider(auth: string, staticMapRequest: StaticMapRequest): Promise<string> {
    const response = await apiClient(auth).post<StaticMapResponse>('/generate-static-map', staticMapRequest)

    return response.data.id
}

export default GenerateStaticMapProvider;
