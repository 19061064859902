import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import AgroMapView from "../map/AgroMapView";
import GeoField from "../../model/field/GeoField";
import EditGeoFieldMapView from "../map/components/EditGeoFieldMapView";
import { message, Spin } from "antd";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { AddGeoFieldProvider } from "../../network/field/AddFieldProvider";
import { useTranslation } from "react-i18next";
import AgroMapProvider, { AgroMapMode, useAgroMap } from "../map/AgroMapProvider";
import AgroMapUIView from "../map/components/AgroMapUIView";
import AddFieldsActionsView from "../map/components/AddFieldsActionsView";
import { Size } from "../../utils/UseElementSize";
import useResizeObserver from "@react-hook/resize-observer";
import { useFieldsManager } from "../../utils/field/FieldProvider";

const MapHomeView: React.FC = () => {
    const {t} = useTranslation()
    const [messageApi, messageContextHolder] = message.useMessage();
    const authHeader = useAuthHeader()!
    const [isLoading, setIsLoading] = useState(false)
    const agroMap = useAgroMap()
    const agroMapRef = useRef<HTMLDivElement>(null)
    const fieldManager = useFieldsManager()
    const [size, setSize] = useState<Size>({width: 0, height: 0})

    const setRoundedSize = ({width, height}: Size) => {
        setSize({width: Math.round(width), height: Math.round(height)});
    };

    useLayoutEffect(() => {
        agroMapRef.current && setRoundedSize(agroMapRef.current.getBoundingClientRect())
    }, [agroMapRef]);

    useResizeObserver(agroMapRef, entry => {
        const {inlineSize: width, blockSize: height} = entry.contentBoxSize[0];
        setRoundedSize({width, height});
    });

    useEffect(() => {
        fieldManager.requestFieldsUpdate()
    }, [])

    useEffect(() => {
        setIsLoading(fieldManager.isFieldLoading)
    }, [fieldManager.isFieldLoading]);

    const onAddGeoField = useCallback(async (geoField: GeoField) => {
        try {
            setIsLoading(true)

            await AddGeoFieldProvider(authHeader, [geoField])
            agroMap.setMode(null)
            setIsLoading(false)
            fieldManager.requestFieldsUpdate()
        } catch (e) {
            setIsLoading(false)
            messageApi.error(t('errorAddField'))
        }

    }, [])

    return (
        <div
            ref={agroMapRef}
            style={{
                position: 'relative',
                display: 'flex',
                width: '100%',
                height: '100%',
            }}>
            {isLoading && <Spin
                style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                }}
                spinning={isLoading}
            />}
            <AgroMapView
                fields={fieldManager.fields}
                onAddGeoField={onAddGeoField}
            />

            <div
                style={{
                    position: 'absolute',
                    right: 50,
                    left: 50,
                    pointerEvents: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
                <AgroMapUIView/>

                {(agroMap.mode === AgroMapMode.FIELD_EDIT || fieldManager.fields.length == 0)? null : <AddFieldsActionsView size={size}/>}

                {agroMap.mode == AgroMapMode.FIELD_EDIT && agroMap.editField !== null && agroMap.map !== undefined ?

                    <EditGeoFieldMapView
                        geoField={agroMap.editField!}
                        map={agroMap.map!}
                        onAddGeoField={onAddGeoField}
                    /> : null}
            </div>

            {messageContextHolder}
        </div>
    )
}

const HomeView: React.FC = () => {
    return (
        <AgroMapProvider>
            <MapHomeView/>
        </AgroMapProvider>
    )
}
export default HomeView;
