import axios from "axios";

const apiClient = (auth: string | undefined = undefined) => {
    const API_URL = process.env.REACT_APP_API_URL
    const instance = axios.create({
        baseURL: API_URL,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': auth,
        }
    })

    instance.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                console.log('Unauthorized')
                window.location.href = '/login'
            }
            return Promise.reject(error)
        }
    )

    return instance
}

export default apiClient
