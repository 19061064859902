import HeaderView from './header/HeaderView';
import SideMenuView from './sidemenu/SideMenuView';
import { Layout, Spin } from "antd";

const {Header: AntHeader} = Layout;
import MainContent from "./maincontent/maincontent";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import { SessionAppContext } from "../../utils/session_app_state_provider";
import LogoutTimer from "../../utils/logout_timer";
import { AppContext } from "../../utils/app_state_provider";
import UserProvider from "../../network/user_provider";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import GetFarmService from "../../network/farm/GetFarmService";
import User from "../../model/user";
import Farm from "../../model/farm/farm";

const logoutTimer = new LogoutTimer()

const Dashboard: React.FC = () => {
    const {appState, rememberMeChecked} = useContext(AppContext)!
    const authHeader = useAuthHeader()!
    const {sessionAppState, updateUser, updateFarm, putUser, putFarm} = useContext(SessionAppContext)!
    const navigate = useNavigate()
    const signOut = useSignOut()
    const [userOnboarded, setUserOnboard] = useState<boolean>(false)
    const [isCollapsible, setCollapsible] = useState<boolean>(false)
    const [contentLeftMargin, setContentLeftMargin] = useState<string>('350px')
    const [isCollapsed, setCollapsed] = useState<boolean>(false)

    const onBreakpointChange = (broke: boolean) => {
        setCollapsible(broke)
        setContentLeftMargin(broke ? '0px' : '250px')
        setCollapsed(broke)
    }

    const onMenuButtonClick = () => {
        setCollapsed(false)
    }

    const onCloseButtonClick = () => {
        setCollapsed(true)
    }

    useEffect(() => {
        const farmPromise: Promise<Farm | undefined> = GetFarmService(authHeader)
        const userPromise: Promise<User> = UserProvider(authHeader)
        Promise.all([farmPromise, userPromise]).then(([farm, user]) => {
            putUser(user)
            putFarm(farm)
            if (farm === undefined) {
                navigate('/onboarding')
            } else {
                setUserOnboard(user.isOnboarded)
            }
        })
    }, [])

    const logout = () => {
        signOut()
        navigate('/login')
    }

    if (appState) {
        if (appState?.rememberMeChecked === false) {
            logoutTimer.setupInactivityTimer(logout)
        } else {
            logoutTimer.clearInactivityTimer()
        }
    }

    ['click', 'keydown'].forEach(event =>
        window.addEventListener(event, () => {
            if (logoutTimer.inactivityTimeout && appState?.rememberMeChecked === true) {
                logoutTimer.resetInactivityTimer(logout)
            }
        }))
    return (
        userOnboarded ?
            <Layout>
                <Sider
                    collapsedWidth="0"
                    collapsible={isCollapsible}
                    collapsed={isCollapsed}
                    width="250px"
                    reverseArrow={true}
                    onBreakpoint={onBreakpointChange}
                    breakpoint="lg"
                    style={{
                        display: 'flex',
                        position: 'fixed',
                        zIndex: 1,
                        transition: 'all 0.3s',
                    }}
                >
                    <SideMenuView
                        showCloseButton={isCollapsible}
                        onCloseButtonClick={onCloseButtonClick}
                    />
                </Sider>
                <Layout
                    style={{
                        marginLeft: contentLeftMargin,
                    }}
                >
                    <AntHeader
                        className="responsive-header"
                        style={{
                            display: 'flex',
                            position: 'fixed',
                            height: '60px',
                            padding: 0,
                            width: `calc(100% - ${contentLeftMargin})`,
                        }}>
                        <HeaderView
                            showMenuButton={isCollapsible}
                            onMenuButtonClick={onMenuButtonClick}
                        />
                    </AntHeader>
                    <Content
                        style={{
                            display: 'flex',
                            marginTop: '64px',
                            marginRight: '0px',
                            padding: '24px',
                            borderRadius: '5px',
                            height: 'calc(100vh - 64px)',
                        }}
                    >
                        <MainContent/>
                    </Content>
                </Layout>
            </Layout>
            : <Spin fullscreen/>
    )
}

export default Dashboard