import { Modal } from 'antd';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import Field from "../../../model/field/field";

interface FieldSearchResultModalProps {
    fieldsSearchResult: Array<Field> | undefined;
    singleSearchResult?: Boolean | undefined
    onAccept: (field: Array<Field> | undefined) => void | undefined
}

const FieldSearchResultModal: React.FC<FieldSearchResultModalProps> = (props) => {
    const {t} = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [singleSearch, setSingleSearch] = useState('')

    useEffect(() => {
        if (props.fieldsSearchResult !== undefined) {
            const fieldCount = props.fieldsSearchResult?.length
            setIsModalOpen(fieldCount > 0)
            if (fieldCount === 1) {
                setSingleSearch(props.fieldsSearchResult[0].label)
            }
        } else {
            setIsModalOpen(false)
        }
    }, [props.fieldsSearchResult])


    const onOk = () => {
        props.onAccept(props.fieldsSearchResult)
        setIsModalOpen(false)
    }

    const onCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <Modal
            style={{ overflow: 'auto' }}
            centered
            open={isModalOpen}
            onOk={onOk}
            onCancel={onCancel}
            okText={t('fieldsAddSearchResultAccept')}
            cancelText={t('fieldsAddSearchResultCancel')}
            closeIcon={false}
        >
            {props.fieldsSearchResult?.length === 1 ?
                <div>
                    <Title level={5}>{t('fieldsAddSearchResultSingleTitle')}</Title>
                    <Paragraph>{singleSearch}</Paragraph>
                </div>
                :
                <ul>
                    {props.fieldsSearchResult?.map((field, index) => {
                        return (
                            <div>
                                <Paragraph>{t('fieldsAddSearchResultManyItemPlaceholder', {
                                    index: index + 1,
                                    label: field.label
                                })}</Paragraph>
                            </div>
                        )
                    })}
                </ul>
            }
        </Modal>
    )
}

export default FieldSearchResultModal;
