import apiClient from "../network_provider";
import FieldHistoryEntry from "../../model/field/FieldHistoryEntry";
import { FieldAction } from "../../model/field/FieldAction";

async function GetFieldHistoryProvider(authHeader: string, id: string, fieldAction: string | null): Promise<Array<FieldHistoryEntry>> {
    const params = fieldAction ? {params: {type: fieldAction}} : {};
    const response = await apiClient(authHeader).get<Array<FieldHistoryEntry>>(`/field/history/${id}`, params)
    return response.data
}

export default GetFieldHistoryProvider
