import apiClient from "../network_provider";
import Fertilizer from "../../model/fertilizing/Fertilize";

export async function AddFertilizerService(authToken: string, fertilizeName: string) {
    await apiClient(authToken).put('/fertilizer', { name: fertilizeName })
}

export async function GetFertilizerService(authToken: string): Promise<Fertilizer[]> {
    const response = await apiClient(authToken).get<Fertilizer[]>('/fertilizers')
    return response.data
}
