class LogoutTimer {
    inactivityTimeout: any;

    setupInactivityTimer(action: () => void): void {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(() => {
            action()
        },  60 * 10 * 1000); // 5 minutes
    };

    resetInactivityTimer (action: () => void): void {
        this.setupInactivityTimer(action);
    };

    clearInactivityTimer(): void {
        clearTimeout(this.inactivityTimeout);
    }
}

export default LogoutTimer;
