import apiClient from "../network_provider";
import AuthResponse from "../../model/login/AuthResponse";
import AuthRequest from "../../model/login/AuthRequest";

async function LoginProvider(authRequest: AuthRequest): Promise<AuthResponse> {
    const response = await apiClient().post<AuthResponse>('/auth/login', authRequest)
    return response.data
}

export default LoginProvider;
