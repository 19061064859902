import GeoField from "../../model/field/GeoField";
import { LatLng, LatLngBounds } from "leaflet";
import MKOParams from "../../model/field/MKOParams";
import proj4 from "proj4";
import Coordinates from "../../model/coordinates";
import * as turf from '@turf/turf';

export function polygons(boundaries: Coordinates[][]): LatLng[][] {
    return boundaries.map(boundary =>
        boundary.map(boundary => new LatLng(boundary.latitude, boundary.longitude))
    )
}

export function bounds(boundaries: Coordinates[][]): LatLngBounds {
    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLon = Infinity;
    let maxLon = -Infinity;
    boundaries.forEach(boundary => {
        boundary.forEach((boundary) => {
            minLat = Math.min(minLat, boundary.latitude);
            maxLat = Math.max(maxLat, boundary.latitude);
            minLon = Math.min(minLon, boundary.longitude);
            maxLon = Math.max(maxLon, boundary.longitude);
        })
    })

    const southWest = new LatLng(minLat, minLon)
    const northEast = new LatLng(maxLat, maxLon)
    return new LatLngBounds(southWest, northEast)
}

export function centerPosition(geoField: GeoField): LatLng {
    return new LatLng(geoField.center.latitude, geoField.center.longitude)
}

export function getMKOParams(map: L.Map, position: LatLng): MKOParams {
    const {lat, lng} = position;
    const size = map.getSize();
    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    // Calculate the WMS GetFeatureInfo parameters
    const x = Math.round(size.x * (lng - sw.lng) / (ne.lng - sw.lng));
    const y = Math.round(size.y * (ne.lat - lat) / (ne.lat - sw.lat));

    const sourceProjection = 'EPSG:4326'; // WGS84, used worldwide for GPS coordinates
    const destinationProjection = 'EPSG:2180'; // Polish projection

    const projectedSW = proj4(sourceProjection, destinationProjection, [sw.lng, sw.lat]);
    const projectedNE = proj4(sourceProjection, destinationProjection, [ne.lng, ne.lat]);

    const bbox = `${projectedSW[0]},${projectedSW[1]},${projectedNE[0]},${projectedNE[1]}`;
    return ({
        x: x,
        y: y,
        bbox: bbox,
        width: size.x,
        height: size.y
    });
}

export function checkIfPointInPolygon(point: Coordinates, polygon: Coordinates[][]): boolean {
    const turfPoint = turf.point([point.longitude, point.latitude]);
    const newBoundary = [...polygon]
    const nb = newBoundary
        .map((boundary) => {
            if (boundary[0].latitude !== boundary[boundary.length - 1].latitude || boundary[0].longitude !== boundary[boundary.length - 1].longitude) {
                boundary.push(boundary[0]);
            }
            return boundary
        })
        .map(boundary => boundary.map(boundary => [boundary.longitude, boundary.latitude]))
    const turfPolygon = turf.polygon(nb);
    return turf.booleanPointInPolygon(turfPoint, turfPolygon);
}
