import React, { useContext, useEffect, useState } from "react";
import { SessionAppContext } from "../../utils/session_app_state_provider";
import { useTranslation } from "react-i18next";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import { Button, Input, Spin } from "antd";
import './welcome.css'
import { useNavigate } from "react-router-dom";
import AddFarmService from "../../network/farm/AddFarmService";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import GetFarmService from "../../network/farm/GetFarmService";

const Welcome: React.FC = () => {
    const authHeader = useAuthHeader()!
    const {t} = useTranslation()
    const {sessionAppState, updateUser, updateFarm, putUser, putFarm} = useContext(SessionAppContext)!
    const [hasFarm, setHasFarm] = useState<Boolean>(sessionAppState.farm !== undefined)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [farmName, setFarmName] = useState('')

    useEffect(() => {
        async function validateIfUserHasFarm() {
            setLoading(true)
            const farm = await GetFarmService(authHeader)
            if (farm !== undefined) {
                navigate('/')
            }
            setLoading(false)
        }

        validateIfUserHasFarm()
    }, []);

    const addFarm = async () => {
        setLoading(true)
        try {
            await AddFarmService(authHeader, farmName)
            const farm = await GetFarmService(authHeader)

            if (farm !== undefined) {
                setFarmName(farm?.name)
                putFarm(farm)
                navigate('/')
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.error(e)
        }
    }

    const onFarmNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFarmName(e.target.value)
    }

    return (
        <div className={'Welcome'}>
            {loading ?
                <div><Spin fullscreen/></div> :
                <div className={'Welcome-Content'}>
                    <Title level={1}>{t('welcomeGreetingsTitle', {name: sessionAppState.user?.firstName})}</Title>
                    <Paragraph style={{whiteSpace: 'pre-line'}}
                               className={'Paragraph-Thanks'}>{t('welcomeGreetingsMessage')}</Paragraph>

                    <div>
                        <Paragraph style={{whiteSpace: 'pre-line'}}
                                   className={'Paragraph-Start'}>{t('welcomeProvideFarmName')}</Paragraph>
                        <Input
                            style={{textAlign: 'center',}}
                            placeholder={t('welcomeFarmName')}
                            allowClear
                            maxLength={150}
                            onChange={onFarmNameChange}
                        />
                        <Button
                            type="primary"
                            className={'Button-Start'}
                            onClick={addFarm}
                            disabled={farmName.length <= 2}
                            loading={loading}
                        >
                            {t('welcomeProvideFarmApply')}
                        </Button>
                    </div>
                </div>}
        </div>
    )
}

export default Welcome
