import './maincontent.css'
import React from "react";
import { Outlet } from "react-router-dom";

const MainContent: React.FC = () => {
    return (
        <div className={'MainContent'}>
            <Outlet/>
        </div>
    )
}

export default MainContent
