import CropCatalog, { CropType } from "../../../../model/cropcatalog/CropCatalog";
import React, { useEffect, useRef, useState } from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useTranslation } from "react-i18next";
import CropsCatalogProvider, { AddCropCatalogProvider } from "../../../../network/cropcatalog/crop_catalog_provider";
import Paragraph from "antd/lib/typography/Paragraph";
import { Button, Cascader, Input, InputNumber, Select, Spin } from "antd";
import { TFunction } from "i18next";
import Dose from "../../../../model/Dose";
import CropAction from "../../../../model/crop/CropAction";

function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T[]> {
    return list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);
}

function isStringArray(value: any): value is string[] {
    return Array.isArray(value) && value.every(item => typeof item === 'string');
}

function convertCropCatalogToCascaderOptions(cropCatalog: CropCatalog[], t: TFunction<"translation", undefined>): any[] {
    const groupedCatalog: Record<CropType, CropCatalog[]> = groupBy(cropCatalog, (crop) => crop.type)

    return Object.entries(groupedCatalog).map(([key, value]) => {
        const values = value.map((crop) => {
            return {
                value: crop.id,
                label: crop.title.pl,
            }
        })

        return {
            value: key,
            label: t('cropType_' + key),
            children: [...values, {
                value: 'addNew',
                label: t('addNewItem')
            }]
        }
    })
}

interface SowingPlantingViewProps {
    onCropSelected: (cropAction: CropAction | null) => void
}

const SowingPlantingView: React.FC<SowingPlantingViewProps> = (props) => {
    const authHeader = useAuthHeader()!
    const [isCropLoading, setIsCropLoading] = useState<boolean>(false)
    const [cropCatalogs, setCropCatalogs] = useState<CropCatalog[]>([])
    const [cascaderOptions, setCascaderOptions] = useState<any[]>([])
    const [cascaderDefault, setCascaderDefault] = useState<string[]>([])
    const [isAddNewCrop, setIsAddNewCrop] = useState<boolean>(false)
    const [cropType, setCropType] = useState<CropType | null>(null)
    const [newCropTypeName, setNewCropTypeName] = useState<string | null>(null)
    const {t} = useTranslation()
    const cascaderRef = useRef<any>()
    const [cropCatalog, setCropCatalog] = useState<CropCatalog | null>(null)

    const [dose, setDose] = useState<Dose>({
        amount: 0,
        unit: t('kgPerHa')
    })

    const onDoseChange = (newAmount: number | null) => {
        setDose({
            ...dose,
            amount: newAmount || 0
        })
    }
    const onDoseUnitChange = (newDoseUnit: string) => {
        setDose({
            ...dose,
            unit: newDoseUnit
        })
    }

    const unitSelect = (
        <Select defaultValue="kg/Ha"
                style={{width: 150}}
                onChange={onDoseUnitChange}
        >
            <Select.Option value={t('unitPerHa')}>{t('unitPerHa')}</Select.Option>
            <Select.Option value={t('kgPerHa')}>{t('kgPerHa')}</Select.Option>
        </Select>
    )
    const loadCrops = async () => {
        try {
            setIsCropLoading(true)
            const cropCatalog = await CropsCatalogProvider(authHeader)
            setCropCatalogs(cropCatalog)
            const cascaderOptions = convertCropCatalogToCascaderOptions(cropCatalog, t)
            setCascaderOptions(cascaderOptions)
            setIsCropLoading(false)
        } catch (e) {
            setIsCropLoading(false)
            console.error(e)
        }
    }

    useEffect(() => {
        loadCrops()

        return () => {
            setCropCatalogs([])
            setCascaderOptions([])
            setCascaderDefault([])
            setIsAddNewCrop(false)
            setCropType(null)
            setNewCropTypeName(null)
            props.onCropSelected(null)
        }
    }, [])

    const onCascadeCleared = () => {
        props.onCropSelected(null)
    }

    const onCascadeChange = (value: any) => {
        if (isStringArray(value)) {
            console.log(value[0], value[1])
            const cropType = Object.values(CropType).find((crop) => crop === value[0])
            if (value[1] === 'addNew') {
                setCropType(cropType || null)
                setIsAddNewCrop(true)
            } else {
                const selectedCrop = cropCatalogs.find((crop) => crop.id == value[1])
                if (selectedCrop === undefined) return
                setCropCatalog(selectedCrop)
            }
        }
    }

    const onAddNewCrop = async () => {
        const localCropType = Object.values(CropType).find((crop) => crop === cropType)
        if (localCropType === undefined || newCropTypeName === null) return
        setIsCropLoading(true)
        await AddCropCatalogProvider(authHeader, {
            name: newCropTypeName!,
            type: localCropType,
            beginType: null,
            endType: null,
        })
        await loadCrops()
        setIsCropLoading(true)
    }

    useEffect(() => {
        const localCropType = Object.values(CropType).find((crop) => crop === cropType)
        if (isAddNewCrop && localCropType !== undefined) {
            setIsCropLoading(false)
            const defaultValue = cropCatalogs.find((crop) => crop.name == newCropTypeName)
            setCascaderDefault([localCropType, defaultValue!.id])
            setIsCropLoading(false)
            setCropType(null)
            setIsAddNewCrop(false)
            setNewCropTypeName(null)
            if (defaultValue === undefined) return
            setCascaderDefault([localCropType, defaultValue!.id])
            setCropCatalog(defaultValue)
        }
    }, [cropCatalogs]);

    useEffect(() => {
        if (cropCatalog === null) {
            props.onCropSelected(null)
            return
        }
        props.onCropSelected({
            cropCatalog: {
                id: cropCatalog.id,
                name: cropCatalog.title.pl,
                type: cropCatalog.type,
            },
            dosePerHectare: dose
        })
    }, [dose, cropCatalog]);

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
            marginTop: 10,
            marginBottom: 10,
        }}>
        <Paragraph style={{margin: 0}}>{t('mapFieldActionSelectCropKind')}</Paragraph>
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        }}>
            {isCropLoading && <div>
                <Spin/>
                <Paragraph style={{margin: 0, marginTop: 10}}>{t('loading')}</Paragraph>
            </div>
            }
            {cropCatalogs.length > 0 && !isAddNewCrop && !isCropLoading && <Cascader
                style={{
                    textAlign: 'start',
                    margin: 0,
                    width: '100%'
                }}
                ref={cascaderRef}
                defaultValue={cascaderDefault}
                onClear={onCascadeCleared}
                onChange={onCascadeChange}
                options={cascaderOptions}
                placeholder={t('mapFieldActionAddNewCropSelect')}/>}
            {isAddNewCrop && !isCropLoading && <div
                style={{
                    width: '100%',
                    border: '1px solid #d9d9d9',
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                }}
            >
                <Paragraph style={{
                    alignItems: 'start',
                    alignContent: 'start',
                    alignSelf: 'start',
                    margin: 0
                }}>{t('mapFieldActionAddNewCropTo') + t('cropType_' + cropType)}</Paragraph>
                <Input style={{
                    width: '100%'
                }}
                       placeholder={t('mapFieldActionAddNewCropName')}
                       onChange={(e) => setNewCropTypeName(e.target.value)}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    width: '100%',
                    marginTop: 10,
                }}>
                    <Button type={'primary'} onClick={onAddNewCrop}>{t('add')}</Button>

                    <Button
                        style={{marginLeft: 10}}
                        danger onClick={() => {
                        setIsAddNewCrop(false)
                        setCropType(null)
                    }}>{t('cancel')}</Button>
                </div>
            </div>}

        </div>

        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
            }}>
            <Paragraph style={{
                width: '100%',
                textAlign: 'start',
                margin: 0,
                marginTop: 10
            }}>{t('dosePerHectare')}</Paragraph>
            <InputNumber
                addonAfter={unitSelect}
                onChange={onDoseChange}
                defaultValue={0}
                controls
            />
        </div>
    </div>
}

export default SowingPlantingView;
