import Field from "../../model/field/field";
import { LatLngBounds } from "leaflet";

function FieldsToBoundingBox(fields: Array<Field>): number[][] {
    if (fields.length === 0) return [[54.2858, 14.1229], [49.5020, 24.1458]];

    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLon = Infinity;
    let maxLon = -Infinity;


    fields.forEach((field) => {
        field.boundaries.forEach((boundaries) => {
            boundaries.forEach((boundary) => {
                minLat = Math.min(minLat, boundary.latitude);
                maxLat = Math.max(maxLat, boundary.latitude);
                minLon = Math.min(minLon, boundary.longitude);
                maxLon = Math.max(maxLon, boundary.longitude);
            })
        })
    })

    return [[minLat, minLon], [maxLat, maxLon]]
}

export function fieldsToBoundingBox(fields: Array<Field>): LatLngBounds {
    const boundingBox = FieldsToBoundingBox(fields);
    return new LatLngBounds([boundingBox[0][0], boundingBox[0][1]], [boundingBox[1][0], boundingBox[1][1]]);
}

export default FieldsToBoundingBox;
