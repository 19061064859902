import apiClient from "./network_provider";
import FieldSearchResult from "../model/field_search_result";

async function AddFieldsByImage(auth: string, file: any, onProgress: (t: number) => {}): Promise<Array<FieldSearchResult>>  {
    const formData = new FormData()
    formData.append('file', file)

    const axiosRequestConfig = {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress(percentCompleted);
        }
    }

    const result = await apiClient(auth).post<Array<FieldSearchResult>>('/field/info-upload', formData, axiosRequestConfig)
    console.log("Result: ", result)
    return result.data
}

export default AddFieldsByImage;