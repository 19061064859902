import Field from "../../model/field/field";
import apiClient from "../network_provider";
import { AxiosRequestConfig } from "axios";
import GeoField from "../../model/field/GeoField";
import Coordinates from "../../model/coordinates";

interface GeoFieldResponse {
    registrationNumber: string;
    label: string;
    center: Coordinates;
    boundary: Coordinates[];
    area: number;
}

async function SearchFieldByLatLngProvider(authHeader: string, lat: number, lng: number): Promise<GeoField | null> {
    const requestConfig: AxiosRequestConfig = {
        params: {
            latlng: lat + "," + lng
        }
    }
    try {
        const response = await apiClient(authHeader).get<GeoFieldResponse>('/field/searchByLatLng', requestConfig)
        return {
            registrationNumber: response.data.registrationNumber,
            label: response.data.label,
            center: response.data.center,
            boundaries: [response.data.boundary],
            area: response.data.area,
            mkoArea: null,
            fieldName: response.data.registrationNumber
        }
    } catch (e) {
        console.log(e)
        return null
    }
}

export default SearchFieldByLatLngProvider
