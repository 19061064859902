import Title from "antd/lib/typography/Title";
import React, { useEffect, useRef, useState } from "react";
import { Button, Collapse, DatePicker, Input, message, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { AgroMapMode, useAgroMap } from "../AgroMapProvider";
import dayjs from "dayjs";
import Paragraph from "antd/lib/typography/Paragraph";
import Field from "../../../model/field/field";
import { CloseCircleOutlined, WarningFilled } from "@ant-design/icons";
import { Tooltip } from "antd/lib";
import SowingPlantingView from "./fieldaction/SowingPlantingView";
import SprayingView from "./fieldaction/SprayingView";
import FertilizingView from "./fieldaction/FertilizingView";
import SprayingAction from "../../../model/spraying/SprayingAction";
import FertilizeAction from "../../../model/fertilizing/FertilizeAction";
import { Size } from "../../../utils/UseElementSize";
import CropAction from "../../../model/crop/CropAction";
import AddFieldAction, { FieldData } from "../../../model/field/AddFieldAction";
import { FieldAction } from "../../../model/field/FieldAction";
import CommentsView from "./fieldaction/CommentsView";
import AddFieldActionProvider from "../../../network/field/AddFieldActionProvider";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useFieldsManager } from "../../../utils/field/FieldProvider";
import Performer from "../../../model/performer/Performer";
import PerformerView from "./fieldaction/PerformerView";
import ActionAreaView from "./fieldaction/ActionAreaView";

interface SelectedFieldProps {
    field: Field,
    index: number,
    fieldAction: FieldAction,
    onFieldDataUpdated: (fieldData: FieldData) => void,
}

const SelectedFieldView: React.FC<SelectedFieldProps> = (props) => {
    const agroMap = useAgroMap()
    const [areaAction, setAreaAction] = useState<number>(props.field.area)
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const {t} = useTranslation()
    const cropSowingPlantingWarn = props.fieldAction === FieldAction.SOWING_PLANTING && props.field.crop !== null
    const cropHarvestingWarn = props.fieldAction === FieldAction.HARVESTING_DIGGING && props.field.crop === null
    const hasWarn = cropSowingPlantingWarn || cropHarvestingWarn
    const onActionAreaChanged = (newArea: number) => {
        setAreaAction(newArea)
        props.onFieldDataUpdated({id: props.field.id, areaAction: newArea})
    }

    return <div
        style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            border: isHovered ? '1px solid #c0c0c0' : '1px solid transparent',
            borderRadius: 5,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 10,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'start',
                width: '100%',
                opacity: hasWarn ? 0.5 : 1,
            }}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
            }}
            >
                <Paragraph style={{
                    margin: 0,
                    width: 30,
                    fontSize: 16,
                }}>{props.index + 1 + '. '}</Paragraph>
                <Paragraph style={{
                    margin: 0,
                    fontSize: 15,
                }}>{props.field.registrationNumber}</Paragraph>
                {cropSowingPlantingWarn &&
                    <Tooltip title={t('mapFieldAddActionSelectedCropSowingWarn', {cropName: props.field.crop!.name})}>
                        <WarningFilled style={{marginLeft: 5}}/>
                    </Tooltip>}
                {cropHarvestingWarn &&
                    <Tooltip title={t('mapFieldAddActionSelectedCropHarvestingWarn')}>
                        <WarningFilled style={{marginLeft: 5}}/>
                    </Tooltip>}
            </div>

            <ActionAreaView
                actionArea={areaAction}
                onChanged={onActionAreaChanged}
                action={props.fieldAction}/>
        </div>
        {isHovered && <CloseCircleOutlined onClick={() => {
            agroMap.onFieldUnselected(props.field)
        }}/>}
    </div>
}

interface FieldAreaAction {
    id: string,
    areaAction: number,
}

interface SelectedFieldsProps {
    fields: Field[],
    fieldAction: FieldAction,
    onFieldsUpdated: (fields: FieldData[]) => void,
}

const SelectedFieldsView: React.FC<SelectedFieldsProps> = (props) => {
    const {t} = useTranslation()

    const onFieldDataUpdated = (fieldData: FieldData) => {
        const newFields: FieldData[] = props.fields.map((field) => {
            if (field.id === fieldData.id) {
                return {...field, area: fieldData.areaAction}
            }
            return field
        })
            .map((field) => {
                return {id: field.id, areaAction: field.area}
            })
        props.onFieldsUpdated(newFields)
    }

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        width: '100%',
    }}>

        {props.fields.map((field, index) => {
            return <SelectedFieldView
                field={field}
                index={index}
                fieldAction={props.fieldAction}
                onFieldDataUpdated={onFieldDataUpdated}
            />
        })}
        {props.fields.length === 0 && <div
            style={{
                height: 40,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}>
            <Paragraph style={{
                margin: 0
            }}>{t('mapFieldAddActionRequestSelectFields')}</Paragraph>
        </div>}
    </div>
}

function validateAddFieldAction(addFieldAction: AddFieldAction): boolean {
    if (addFieldAction.fieldsData.length === 0) return false
    if (addFieldAction.action === FieldAction.SOWING_PLANTING && addFieldAction.cropAction === null) return false
    if (addFieldAction.action === FieldAction.SPRAYING && addFieldAction.sprayingAction === null) return false
    if (addFieldAction.action === FieldAction.FERTILIZING && addFieldAction.fertilizingAction === null) return false
    return true
}

interface AddFieldsActionsProps {
    size: Size,
}

const AddFieldsActionsView: React.FC<AddFieldsActionsProps> = (props) => {
    const authHeader = useAuthHeader()!
    const {t} = useTranslation()
    const [selectedFieldAction, setSelectedFieldAction] = useState<FieldAction>(FieldAction.HARVESTING_DIGGING)
    const [addFieldAction, setAddFieldAction] = useState<AddFieldAction>({
        fieldsData: [],
        action: selectedFieldAction,
        performer: null,
        cropAction: null,
        sprayingAction: null,
        fertilizingAction: null,
        date: dayjs(),
        comment: null
    })
    const agroMap = useAgroMap()
    const [selectedFields, setSelectedFields] = useState<Field[]>([])
    const [areDataReady, setAreDataReady] = useState<boolean>(false)
    const [isActionAdd, setIsActionAdd] = useState<boolean>(false)
    const [messageApi, contextHolder] = message.useMessage();
    const fieldsManager = useFieldsManager()

    useEffect(() => {
        setSelectedFields(agroMap.selectedFields)
        setAddFieldAction({
            ...addFieldAction,
            fieldsData: agroMap.selectedFields.map((field) => ({
                id: field.id, areaAction: field.area,
            }))
        })
    }, [agroMap.selectedFields]);

    const onBeginAdd = () => {
        agroMap.setMode(AgroMapMode.FIELD_ADD)
        setSelectedFields([])
        setSelectedFieldAction(FieldAction.HARVESTING_DIGGING)
        setAddFieldAction({
            fieldsData: [],
            action: FieldAction.HARVESTING_DIGGING,
            performer: null,
            cropAction: null,
            sprayingAction: null,
            fertilizingAction: null,
            date: dayjs(),
            comment: null
        })
    }
    const onCancelAdd = () => {
        agroMap.setEditField(null)
        agroMap.setMode(null)
    }

    const onAddActionToFields = async () => {
        console.log(addFieldAction)
        try {
            setIsActionAdd(true)
            await AddFieldActionProvider(authHeader, addFieldAction)
            fieldsManager.requestFieldsUpdate()
            onCancelAdd()
            setIsActionAdd(false)
            messageApi.success(t('mapFieldAddActionSuccess'))
        } catch (error) {
            messageApi.error(t('mapFieldAddActionError'))
            console.log(error)
            setIsActionAdd(false)
        }
    }

    const onDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
        if (date === null) return
        setAddFieldAction({...addFieldAction, date: date})
    }

    const onActionChange = (value: FieldAction) => {
        setSelectedFieldAction(value)
        setAddFieldAction({...addFieldAction, action: value})
    }

    const onPerformerChange = (performer: Performer | null) => {
        console.log("Performer: ", performer)
        setAddFieldAction({...addFieldAction, performer: performer})
    }

    const selectedCrop = (crop: CropAction | null) => {
        console.log("Crop: ", crop)
        setAddFieldAction({...addFieldAction, cropAction: crop})
    }

    const selectedSpraying = (spraying: SprayingAction | null) => {
        console.log("Spraying: ", spraying)
        setAddFieldAction({...addFieldAction, sprayingAction: spraying})
    }

    const selectedFertilize = (fertilize: FertilizeAction | null) => {
        console.log("Fertilize: ", fertilize)
        setAddFieldAction({...addFieldAction, fertilizingAction: fertilize})
    }

    const onCommentChange = (comment: string | null) => {
        console.log("Comment: ", comment)
        setAddFieldAction({...addFieldAction, comment: comment})
    }

    const onFieldsUpdated = (fields: FieldData[]) => {
        console.log("Fields data: ", fields)
        setAddFieldAction({
            ...addFieldAction,
            fieldsData: fields
        })
    }

    useEffect(() => {
        console.log("AddFieldAction", addFieldAction)
        setAreDataReady(validateAddFieldAction(addFieldAction))
    }, [addFieldAction]);

    const options = [
        {value: FieldAction.HARVESTING_DIGGING, label: t(FieldAction.HARVESTING_DIGGING)},
        {value: FieldAction.SOWING_PLANTING, label: t(FieldAction.SOWING_PLANTING)},
        {value: FieldAction.SPRAYING, label: t(FieldAction.SPRAYING)},
        {value: FieldAction.FERTILIZING, label: t(FieldAction.FERTILIZING)},
        {value: FieldAction.PLOWING, label: t(FieldAction.PLOWING)},
        {value: FieldAction.HARROWING, label: t(FieldAction.HARROWING)},
        {value: FieldAction.LIMING, label: t(FieldAction.LIMING)},
        {value: FieldAction.WATERING, label: t(FieldAction.WATERING)},
    ]

    const actionView = {
        [FieldAction.SOWING_PLANTING]: () => <SowingPlantingView onCropSelected={selectedCrop}/>,
        [FieldAction.SPRAYING]: () => <SprayingView onSprayingSelected={selectedSpraying}/>,
        [FieldAction.FERTILIZING]: () => <FertilizingView onFertilizeSelected={selectedFertilize}/>,
        [FieldAction.PLOWING]: () => null,
        [FieldAction.HARROWING]: () => null,
        [FieldAction.LIMING]: () => null,
        [FieldAction.WATERING]: () => null,
        [FieldAction.HARVESTING_DIGGING]: () => null,
    }

    const fieldCountSuffix = agroMap.mode == AgroMapMode.FIELD_ADD ? ' (' + agroMap.selectedFields.length + ')' : ''

    return <div
        style={{
            pointerEvents: 'auto',
        }}
    >
        {contextHolder}
        <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomRightRadius: 15,
                borderBottomLeftRadius: 15,
                minWidth: 400,
            }}>
            <Collapse.Panel
                style={{
                    paddingTop: 5,
                    paddingRight: 5,
                    paddingLeft: 5,
                    overflow: 'auto',
                }}
                header={<Title style={{
                    margin: 0,
                    marginRight: 10,
                }}
                               level={5}
                >{t('mapFieldActionAddTitle') + fieldCountSuffix}</Title>}
                key={'1'}>
                {agroMap.mode === AgroMapMode.FIELD_ADD &&
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            alignItems: 'start',
                            maxHeight: props.size.height - 80,
                            justifyContent: 'start',
                        }}>
                        <Paragraph style={{margin: 0}}>{t('mapFieldAddActionDate')}</Paragraph>
                        <DatePicker
                            style={{width: '100%'}}
                            allowClear={false}
                            disabledDate={(current) => current && current > dayjs().endOf('day')}
                            onChange={onDateChange}
                            defaultValue={dayjs()}/>

                        <Paragraph style={{
                            margin: 0,
                            marginTop: 10
                        }}>{t('mapFieldAddActionType')}</Paragraph>

                        <Select
                            style={{
                                width: '100%',
                                textAlign: 'start',
                            }}
                            onChange={onActionChange}
                            defaultValue={FieldAction.HARVESTING_DIGGING}
                            options={options}
                        />

                        {Object.values(FieldAction).map((action) => {
                            return selectedFieldAction === action && actionView[action]()
                        })}

                        <PerformerView onPerformerSelected={onPerformerChange}/>

                        <CommentsView onCommentChange={onCommentChange}/>

                        <Paragraph style={{
                            margin: 0,
                            marginTop: 10
                        }}>{t('mapFieldAddActionSelectedFields')}</Paragraph>

                        <SelectedFieldsView
                            fields={selectedFields}
                            fieldAction={selectedFieldAction}
                            onFieldsUpdated={onFieldsUpdated}
                        />

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: 10,
                                paddingTop: 10,
                                paddingBottom: 5,
                                borderTop: '1px solid #e0e0e0',
                                width: '100%',
                            }}
                        >
                            <Button
                                style={{
                                    width: '100%',
                                    marginRight: 10,
                                }}
                                onClick={onAddActionToFields}
                                disabled={!areDataReady}
                                loading={isActionAdd}
                                type={'primary'}
                            >{t('addActionToFields')}</Button>

                            <Button
                                style={{width: '100%'}}
                                onClick={onCancelAdd}
                                disabled={isActionAdd}
                                danger>{t('cancel')}</Button>
                        </div>
                    </div>
                }
                {agroMap.mode === null && <Button
                    style={{width: '100%'}}
                    onClick={onBeginAdd}
                    type={'primary'}
                >{t('mapFieldActionAddTitle')}</Button>}
            </Collapse.Panel>
        </Collapse>
    </div>
}

export default AddFieldsActionsView;
