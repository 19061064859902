import React, { useEffect, useRef, useState } from "react";
import Field from "../../model/field/field";
import GetFieldsProvider from "../../network/fields/GetFieldsService";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { Empty, Image, Modal, Spin, Table, TableColumnsType } from "antd";
import { useTranslation } from "react-i18next";
import useAnalyticsEventTracker, { gaPageViewTracker } from "../../utils/UseAnalyticsEventTracker";
import AgroMapProvider, { useAgroMap } from "../map/AgroMapProvider";
import AgroMapView from "../map/AgroMapView";

interface MapViewProps {
    field: Field
}

interface FieldDetailsViewProps {
    field: Field
}

const FieldDetailsView: React.FC<FieldDetailsViewProps> = (props) => {
    const [field, setField] = useState<Field | undefined>(undefined)

    const agroMap = useAgroMap()
    useEffect(() => {
        setField(props.field)
    }, [props.field])
    return <div

    >

        {field && <div
            style={{
                width: 400,
                height: 400,
            }}
        ><AgroMapView
            fields={field ? [field] : []}/></div>}
    </div>
}

const MapView: React.FC<MapViewProps> = ({field}) => {
    const authHeader = useAuthHeader()!
    const [mapUrl, setMapUrl] = useState("")
    const [mapLoading, isMapLoading] = useState(false)

    useEffect(() => {
        const fetchImage = async () => {
            try {
                isMapLoading(true)

                // const mapUrl = await StaticMapProvider(authHeader, field.imageUrl!)
                // setMapUrl(mapUrl)
                isMapLoading(false)
            } catch (e) {
                isMapLoading(false)
                console.log(e)
            }
        }
        fetchImage()
    }, [])

    useEffect(() => {
        if (mapUrl !== "") {
            isMapLoading(false)
        }
    }, [mapUrl]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 50,
            height: 50,
        }}>
            {mapLoading ? (<div/>) : (
                <Image
                    src={mapUrl}
                    width={50}
                    height={50}
                    placeholder={
                        <Image
                            preview={false}
                            src={mapUrl}
                            width={550}
                            height={550}/>
                    }
                />
            )}
            <Spin spinning={mapLoading}/>
        </div>
    )
}

function getAreaInHa(area: number, t: any): string {
    return t('cropItemFieldArea', {area: (area / 10000).toFixed(2)})
}

interface FieldData {
    key: number;
    registrationNumber: string;
    fieldName: string;
    area: number;
    staticMapImageName: string;
    crop: string;
    cropArea: number;
    field: Field;
}

const FieldsView: React.FC = () => {
    gaPageViewTracker('FieldsView')
    const gaEventTracker = useAnalyticsEventTracker('FieldsView')
    const {t} = useTranslation()
    const authHeader = useAuthHeader()!
    const [isLoading, setIsLoading] = useState<Boolean>(false)
    const [fieldsData, setFieldsData] = useState<Array<FieldData>>([])
    const [scrollHeight, setScrollHeight] = useState<number>();
    const divRef = useRef<HTMLDivElement>(null);
    const [selectedField, setSelectedField] = useState<Field | undefined>(undefined)

    const updateScrollHeight = () => {
        if (divRef.current) {
            setScrollHeight(divRef.current.clientHeight - 120);
        }
    };
    const loadFields = async () => {
        try {
            setIsLoading(true)
            const fields = await GetFieldsProvider(authHeader)
            const newFieldsData = fields.map((field, index) => {
                return ({
                    key: index,
                    registrationNumber: field.registrationNumber,
                    area: field.area,
                    crop: field.crop?.name!,
                    fieldName: field.fieldName,
                    cropArea: field.crop ? field.crop?.cropArea || field.area : undefined,
                    field: field
                }) as FieldData
            })
            setFieldsData(newFieldsData)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }
    }
    useEffect(() => {
        loadFields()
    }, []);

    const columns: TableColumnsType<FieldData> = [
        {
            width: 60,
            align: 'center',
            dataIndex: 'key',
            rowScope: 'row',
        }, {
            title: t('fieldTableColumnRegNumber'),
            key: 'registrationNumber',
            dataIndex: 'registrationNumber'
        }, {
            title: t('fieldTableColumnName'),
            key: 'fieldName',
            dataIndex: 'fieldName'
        }, {
            title: t('fieldTableColumnArea'),
            key: 'area',
            dataIndex: 'area',
            render: (area, field) => (getAreaInHa(area, t))
        }, {
            title: t('fieldTableColumnCrop'),
            key: 'crop',
            dataIndex: 'crop'
        }, {
            title: t('fieldTableColumnCropArea'),
            key: 'cropArea',
            dataIndex: 'cropArea',
            render: (area, field) => (area ? getAreaInHa(area, t) : '')
        }]

    useEffect(() => {
        updateScrollHeight(); // Update on mount

        // Add resize listener to update height on window resize
        window.addEventListener('resize', updateScrollHeight);

        // Cleanup listener on component unmount
        return () => window.removeEventListener('resize', updateScrollHeight);
    }, []);


    return (
        <div
            style={{
                height: '100%',
            }}
            ref={divRef}
        >
            {selectedField && <Modal
                closable
                cancelButtonProps={{
                    style: {
                        display: 'none'
                    }
                }}
                onCancel={() => setSelectedField(undefined)}
                onOk={() => setSelectedField(undefined)}
                open>
                <AgroMapProvider>
                    <FieldDetailsView field={selectedField}/>
                </AgroMapProvider>
            </Modal>}
            <Table
                columns={columns}
                dataSource={fieldsData}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={t('fieldsAddEmptyWithAdd')}
                        />
                    ),
                }}
                bordered
                scroll={{y: scrollHeight}}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            gaEventTracker('FieldDetailsView')
                            setSelectedField(record.field)
                        }
                    }
                }}
            />
        </div>
    )
}

export default FieldsView
