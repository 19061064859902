import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Input } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

interface CommentsProps {
    onCommentChange: (comment: string | null) => void;
}

const CommentsView: React.FC<CommentsProps> = (props) => {
    const {t} = useTranslation()

    const onCommentChange = (newComment: string | null) => {
        props.onCommentChange(newComment)
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
            marginTop: 10,
            width: '100%',
        }}>
            <Paragraph style={{margin: 0}}>{t('mapFieldAddComment')}</Paragraph>
            <Input.TextArea
                maxLength={1000}
                autoSize={{
                    minRows: 1, maxRows: 6
                }}
                placeholder={t('mapFieldAddCommentExample')}
                onChange={(e) => onCommentChange(e.target.value)}
            />
        </div>
    )
}

export default CommentsView;
