import React, { useEffect, useRef, useState } from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import { InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { FieldAction } from "../../../../model/field/FieldAction";
import getAreaInHa from "../../../../utils/FieldUtils";
import { EditOutlined } from "@ant-design/icons";

interface ActionAreaViewProps {
    actionArea: number;
    action: FieldAction;
    onChanged: (newArea: number) => void;
}

const ActionAreaView: React.FC<ActionAreaViewProps> = (props) => {
    const {t} = useTranslation()
    const [label, setLabel] = useState<string>('')
    const [isEdit, setEdit] = useState(false)
    const [newArea, setNewArea] = useState<number>(Math.round(props.actionArea))
    const [areaInHa, setAreaInHa] = useState<boolean>(true)
    const inputRef = useRef(null)

    useEffect(() => {
        switch (props.action) {
            case FieldAction.FERTILIZING:
                setLabel(t('mapFieldAddFertilizingArea'))
                break;
            case FieldAction.SOWING_PLANTING:
                setLabel(t('mapFieldAddCropArea'))
                break;
            case FieldAction.SPRAYING:
                setLabel(t('mapFieldAddSprayingArea'))
                break;
            default:
                setLabel(t('mapFieldAddActionArea'))
        }
    }, [props.action]);

    const onAreaChanged = (newArea: number | null) => {
        if (newArea == null) {
            setNewArea(props.actionArea)
            props.onChanged(props.actionArea)
        } else {
            props.onChanged(newArea as number)
            setNewArea(newArea as number)
        }
    }

    const onFinish = () => {
        setEdit(false)
        props.onChanged(newArea)
    }

    const handleClickOutside = (event: any) => {
        // @ts-ignore
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setEdit(false)
            onFinish()
        }
    };

    useEffect(() => {
        // Add when mounted
        document.addEventListener("mousedown", handleClickOutside);
        // Return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [newArea]);

    return <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start',
        marginLeft: 30,
        width: '100%',
    }}>
        <Paragraph style={{
            margin: 0,
            marginRight: 5
        }}>{label}</Paragraph>
        {isEdit ? <div ref={inputRef}>
            <InputNumber
                style={{
                    margin: 0,
                    fontWeight: "bold",
                }}
                suffix={'m²'}
                className={'custom-input-number'}
                prefixCls={"ant-input-number"}
                size={'small'}
                onPressEnter={onFinish}
                controls={true}
                defaultValue={newArea}
                onChange={onAreaChanged}
            />
        </div> : <Paragraph
            onClick={() => {
                setAreaInHa(!areaInHa)
            }}
            style={{
                cursor: 'pointer',
                margin: 0,
                fontWeight: "bold"
            }}
        >{getAreaInHa(props.actionArea, areaInHa, t)}</Paragraph>}
        <EditOutlined style={{marginLeft: 5}} onClick={() => {
            setEdit(!isEdit)
        }}/>
    </div>
}

export default ActionAreaView;
