import { Button, Card, Image, Popconfirm, Space, Spin, Typography } from "antd";

import React, { useEffect, useState } from "react";
import StaticMapProvider from "../../../network/static_map_provider";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { Trans, useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import Field from "../../../model/field/field";

interface FieldSearchResultItemProps {
    field: Field
    onFieldDelete: (fieldRegNumber: string) => void
}

function ParamItem(props: { i18key: string, value: any }) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            textAlign: 'start'
        }}>

            <Trans
                i18nKey={props.i18key}
                values={props.value}
            />
        </div>
    )
}

function convertAreaToHa(area: number): number {
    const hectares = area / 10000
    return parseFloat(hectares.toFixed(3))
}

const FieldSearchResultItem: React.FC<FieldSearchResultItemProps> = (props) => {
    const authHeader = useAuthHeader()!
    const [mapUrl, setMapUrl] = useState("")
    const [mapLoading, isMapLoading] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        const fetchImage = async () => {
            try {
                isMapLoading(true)

                // await StaticMapProvider(authHeader, props.field.staticMapImageName!).then((result) => {
                //     setMapUrl(result)
                //     isMapLoading(true)
                // })
            } catch (e) {
                console.log(e)
            }
        }
        // if (props.field.staticMapImageName !== undefined) {
        //     fetchImage()
        // }
    }, [props.field.registrationNumber])

    useEffect(() => {
        if (mapUrl !== "") {
            isMapLoading(false)
        }
    }, [mapUrl]);


    return (
        <div style={{marginBottom: 20}}>
            <Card>

                <div style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 150,
                        height: 150,
                    }}>
                        {mapLoading ? (<div/>) : (
                            <Image
                                src={mapUrl}
                                width={150}
                                height={150}
                                placeholder={
                                    <Image
                                        preview={false}
                                        src={mapUrl}
                                        width={550}
                                        height={550}/>
                                }
                            />
                        )}
                        <Spin spinning={mapLoading}/>
                    </div>
                    <Space style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}>

                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                alignItems: 'start',
                                marginLeft: 20,
                                marginRight: 20,
                                flexDirection: 'column',
                            }}>

                            <ParamItem i18key={t('fieldsAddItemRegNumber')}
                                       value={{regNumber: props.field.registrationNumber}}/>
                            <ParamItem i18key={t('fieldsAddItemArea')}
                                       value={{area: convertAreaToHa(props.field.area)}}/>
                            <ParamItem i18key={t('fieldsAddItemDescription')}
                                       value={{desc: props.field.label}}/>
                        </div>

                        <div>
                            <Popconfirm
                                icon={null}
                                title={t('fieldsAddItemDeleteTitle')}
                                okText={t('yes')}
                                cancelText={t('no')}
                                onConfirm={() => props.onFieldDelete(props.field.registrationNumber)}
                                placement={'bottom'}
                            >
                                <Button icon={<DeleteOutlined/>} type={'primary'} danger></Button>
                            </Popconfirm>
                        </div>
                    </Space>
                </div>
            </Card>
        </div>
    )
}

export default FieldSearchResultItem;
