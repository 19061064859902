import React, { useContext, useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox, Card, Spin, Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import './login.css'
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
import { AppContext } from "../../utils/app_state_provider";
import AuthRequest from "../../model/login/AuthRequest";
import LoginProvider from "../../network/login/LoginProvider";
import useAnalyticsEventTracker, { gaPageViewTracker } from "../../utils/UseAnalyticsEventTracker";

interface FormValues {
    email: string
    password: string
    remember: boolean
}

const LoginForm: React.FC = () => {
    const gaEventTracker = useAnalyticsEventTracker('LoginForm')
    gaPageViewTracker('login')
    const [form] = Form.useForm()
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const signIn = useSignIn()
    const {appState, rememberMeChecked} = useContext(AppContext)!
    const {t} = useTranslation()
    const onFinish = async (values: FormValues) => {
        try {
            // @ts-ignore
            gaEventTracker('login')
            setLoading(true)
            const authRequest: AuthRequest = {
                email: values.email,
                password: values.password,
            }
            const response = await LoginProvider(authRequest)
            const accessToken = response.accessToken
            const refreshToken = response.refreshToken

            rememberMeChecked(values.remember)
            const signInResult = signIn({
                auth: {
                    token: accessToken,
                    type: 'Bearer',
                },
                refresh: refreshToken,
            })
            if (signInResult) {
                navigate('/')
            } else {
                showModal()
            }
            setLoading(false)
        } catch (error) {
            // @ts-ignore
            gaEventTracker('loginError')
            setLoading(false)
            showModal()
            console.log('Error:', error)
        }
    }

    const showModal = () => {
        setModal(true)
    }

    const hideModal = () => {
        setModal(false)
    }

    const rememberMeChanged = (e: CheckboxChangeEvent) => {
        rememberMeChecked(e.target.checked)
    }

    const handleForgotPassword = (e: React.FormEvent) => {
        // @ts-ignore
        gaEventTracker('forgotPassword')
        navigate('/resetpassword')
    }

    const handleRegister = (e: React.FormEvent) => {
        // @ts-ignore
        gaEventTracker('register')
        navigate('/register')
    }

    return (
        <div className={'login'}>
            <Spin spinning={loading} fullscreen/>
            <Modal
                open={modal}
                centered={true}
                footer={[
                    <Button key="submit" type="primary" onClick={hideModal}>
                        OK
                    </Button>,
                ]}
                title={t('loginError')}>
                <span>{t('loginErrorMessage')}</span>
            </Modal>
            <Card className={'login-card'}>
                <div>
                    <img src="/logo.png" alt="AgroArchitect logo" className="logo-image"/>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10px',
                    marginTop: '50px'
                }}>
                    {t('login')}
                </div>
                <Form
                    labelCol={{span: 5}}
                    form={form}
                    name="normal_login"
                    className="login-form"
                    initialValues={{remember: true}}
                    onFinish={onFinish}>
                    <Form.Item<FormValues>
                        label={t('registerFieldEmail')}
                        name="email"
                        validateDebounce={1000}
                        rules={[
                            {required: true, message: t('registerFieldEmailRequired')},
                            {type: 'email', message: t('registerFieldEmailIncorrect')}
                        ]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item<FormValues>
                        label={t('registerFieldPassword')}
                        style={{marginTop: '40px', marginBottom: 0}}
                        name="password"
                        validateDebounce={1000}
                        rules={[
                            {min: 8, message: t('registerFieldPasswordMinLength')},
                            {pattern: new RegExp('(?=.*\\d)'), message: t('registerFieldPasswordMinOneDigit')},
                            {required: true, message: t('registerFieldPasswordRequired')}
                        ]}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item>
                        <a
                            style={{float: 'right'}}
                            href=""
                            onClick={handleForgotPassword}>
                            {t('remindPassword')}
                        </a>
                    </Form.Item>
                    <Form.Item>
                        <Form.Item
                            name="remember"
                            valuePropName="checked"
                            noStyle>
                            <Checkbox
                                onChange={rememberMeChanged}
                            >{t('rememberMe')}</Checkbox>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item style={{margin: 0}}>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            {t('login')}
                        </Button>
                    </Form.Item>
                    <Form.Item style={{margin: 0}}>
                        {t('dontHaveAnAccount')} {' '}
                        <a href="" onClick={handleRegister}>
                            {t('register')}
                        </a>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

export default LoginForm
