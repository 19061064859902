import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom"
import LoginForm from "./components/login/login"
import RequireAuth from '@auth-kit/react-router/RequireAuth'
import Dashboard from "./components/dashboard/dashboard";
import FieldsView from "./components/fields/FieldsView";
import Machines from "./components/machines/machines";
import ResetPassword from "./components/dashboard/resetpassword/password_reminder";
import RegisterView from "./components/register/RegisterView";
import AppProvider from "./utils/app_state_provider";
import SessionAppProvider from "./utils/session_app_state_provider";
import Crops from "./components/crops/crops";
import AddFields from "./components/fields/add/add_fields";
import Onboarding from "./components/onboarding/onboarding";
import Welcome from "./components/welcome/welcome";
import CropStatsView from "./components/crops/CropStatsView";
import { gaPageViewTracker } from "./utils/UseAnalyticsEventTracker";
import HomeView from "./components/homeV2/HomeView";
import FieldDetailsView from "./components/fielddetails/FieldDetailsView";
import FieldProvider from "./utils/field/FieldProvider";
import SprayingsView from "./components/sprayings/SprayingsView";
import FertilizersView from "./components/fertilizers/FertilizersView";

function DashboardRoutes() {
    return (
        <RequireAuth fallbackPath='/login'>
            <SessionAppProvider>
                <FieldProvider>
                    <Routes>
                        <Route path="/" element={<Dashboard/>}>
                            <Route path='' element={<HomeView/>}/>
                            <Route path='crops' element={<Crops/>}/>
                            <Route path='crops/stats' element={<CropStatsView/>}/>
                            <Route path='field/:id' element={<FieldDetailsView/>}/>
                            <Route path='fields' element={<FieldsView/>}/>
                            <Route path='fields/add' element={<AddFields/>}/>
                            <Route path='fertilizers' element={<FertilizersView/>}/>
                            <Route path='sprayings' element={<SprayingsView/>}/>
                            <Route path='machines' element={<Machines/>}/>
                        </Route>
                        <Route path="/onboarding" element={<Onboarding/>}>
                            <Route path='' element={<Welcome/>}/>
                            <Route path='add-fields' element={<AddFields/>}/>
                        </Route>
                    </Routes>
                </FieldProvider>
            </SessionAppProvider>
        </RequireAuth>
    )
}

function AppRouter() {
    useEffect(() => {
        gaPageViewTracker(window.location.pathname + window.location.search)
    }, []);
    return (
        <AppProvider>
            <Routes>
                <Route path="/login" element={<LoginForm/>}/>
                <Route path="/register" element={<RegisterView/>}/>
                <Route path="/resetpassword" element={<ResetPassword/>}/>
                <Route path="*" element={<DashboardRoutes/>}/>
            </Routes>
        </AppProvider>
    )
}

export default AppRouter
