import createRefresh from 'react-auth-kit/createRefresh';
import createStore from 'react-auth-kit/createStore';
import apiClient from "./network/network_provider";

export const refreshApi = createRefresh({
    interval: 10, // The time in sec to refresh the Access token,
    refreshApiCallback: async (param) => {
        try {
            const refreshParams = { 'refreshToken': param.refreshToken }
            console.log('refreshApiCallback', refreshParams)
            const response = await apiClient().post("/auth/refresh", refreshParams)
            console.log('refreshApiCallback response', response)
            return {
                isSuccess: true,
                newAuthToken: response.data.accessToken,
                newAuthTokenType: 'Bearer',
                newRefreshToken: response.data.refreshToken,
            }
        } catch (error) {
            console.error(error)
            return {
                isSuccess: false
            }
        }
    }
})

export const store = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
    refresh: refreshApi
})
