import Paragraph from "antd/lib/typography/Paragraph";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Empty, Input, InputNumber, Select, Spin } from "antd";
import { AddFertilizerService, GetFertilizerService } from "../../../../network/fertilize/GetFertilizerService";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import Fertilize from "../../../../model/fertilizing/Fertilize";
import { Space } from "antd/lib";
import Dose from "../../../../model/Dose";
import FertilizeAction from "../../../../model/fertilizing/FertilizeAction";

interface FertilizingProps {
    onFertilizeSelected: (fertilizeAction: FertilizeAction | null) => void
}

const FertilizingView: React.FC<FertilizingProps> = (props) => {
    const {t} = useTranslation()
    const authHeader = useAuthHeader()!
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isFertilizeAdding, setIsFertilizeAdding] = useState<boolean>(false)
    const [addFertilizeName, setAddFertilizeName] = useState<string | null>(null)
    const [fertilizes, setFertilizes] = useState<Fertilize[]>([])
    const [fertilizesOptions, setFertilizesOptions] = useState<any[]>([])
    const [defaultOption, setDefaultOption] = useState<any>([])
    const [fertilize, setFertilize] = useState<Fertilize | null>(null)
    const [dose, setDose] = useState<Dose>({
        amount: 0,
        unit: t('kgPerHa')
    })

    const onDoseChange = (newAmount: number | null) => {
        setDose({
            ...dose,
            amount: newAmount || 0
        })
    }
    const onDoseUnitChange = (newDoseUnit: string) => {
        setDose({
            ...dose,
            unit: newDoseUnit
        })
    }

    const unitSelect = (
        <Select defaultValue={t('kgPerHa')}
                style={{width: 100}}
                onChange={onDoseUnitChange}
        >
            <Select.Option value={t('mlPerHa')}>{t('mlPerHa')}</Select.Option>
            <Select.Option value={t('lPerHa')}>{t('lPerHa')}</Select.Option>
            <Select.Option value={t('gPerHa')}>{t('gPerHa')}</Select.Option>
            <Select.Option value={t('kgPerHa')}>{t('kgPerHa')}</Select.Option>
        </Select>
    )

    const loadFertilizes = async (withLoad: boolean) => {
        try {
            setIsLoading(withLoad)
            const fertilizesResponse = await GetFertilizerService(authHeader)
            setFertilizes(fertilizesResponse)
            setFertilizesOptions(fertilizesResponse.map((fertilize) => {
                return {
                    value: fertilize.id,
                    label: fertilize.name,
                }
            }))
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        loadFertilizes(true)

        return () => {
            setFertilizes([])
            props.onFertilizeSelected(null)
        }
    }, []);

    const onFertilizeSelected = (value: string) => {
        setDefaultOption(null)
        const fertilize = fertilizes.find((fertilize) => fertilize.id === value)
        setFertilize(fertilize || null)
    }

    const onAddFertilize = async () => {
        if (addFertilizeName === null) return
        setIsFertilizeAdding(true)
        await AddFertilizerService(authHeader, addFertilizeName)
        await loadFertilizes(false)
    }

    useEffect(() => {
        if (isFertilizeAdding && addFertilizeName !== null) {
            const newFertilize = fertilizes.find((fertilize) => fertilize.name === addFertilizeName)
            if (newFertilize === undefined) return
            setDefaultOption({
                value: newFertilize.id,
                label: newFertilize.name,
            })
            setIsFertilizeAdding(false)
            setAddFertilizeName(null)
            setFertilize(newFertilize)
        }
    }, [fertilizes]);

    useEffect(() => {
        if (fertilize === null) {
            props.onFertilizeSelected(null)
            return
        }
        props.onFertilizeSelected({
            fertilize: fertilize,
            dosePerHectare: dose
        })
    }, [dose, fertilize]);

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        marginTop: 10,
        width: '100%',
    }}>
        <Paragraph style={{margin: 0}}>{t('mapFieldAddFertilizingSelectOrAddNew')}</Paragraph>

        {isLoading && <div
            style={{
                width: '100%',
            }}>
            <Spin/>
            <Paragraph style={{margin: 0, marginTop: 10}}>{t('loading')}</Paragraph>
        </div>
        }
        {!isLoading && <Select
            style={{
                width: '100%',
                textAlign: 'start',
            }}
            placeholder={t('mapFieldAddFertilizingSelect')}
            allowClear={true}
            showSearch={true}
            value={defaultOption !== null ? defaultOption.value : undefined}
            notFoundContent={<div>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('mapFieldAddFertilizingEmpty')}
                />
            </div>}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input)}
            onChange={onFertilizeSelected}
            options={fertilizesOptions}
        />}

        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                marginTop: 10,
            }}>

            <Paragraph style={{
                margin: 0,
                textAlign: 'start',
            }}>{t('addNewFertilizer')}</Paragraph>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input
                    style={{
                        textAlign: 'start',
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                    }}
                    value={addFertilizeName || ''}
                    onChange={(e) => setAddFertilizeName(e.target.value)}
                    placeholder={t('mapFieldAddFertilizingAddNew')}
                />
                <Button
                    style={{
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,

                    }}
                    loading={isFertilizeAdding}
                    disabled={(addFertilizeName?.length || 0) < 3}
                    onClick={onAddFertilize}
                    type="primary">{t('add')}</Button>
            </Space.Compact>
        </div>
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
            }}>
            <Paragraph style={{
                width: '100%',
                textAlign: 'start',
                margin: 0,
                marginTop: 10
            }}>{t('dosePerHectare')}</Paragraph>
            <InputNumber
                addonAfter={unitSelect}
                onChange={onDoseChange}
                defaultValue={0}
                controls
            />
        </div>
    </div>
}

export default FertilizingView
