import Crop from "../../model/crop/Crop";
import apiClient from "../network_provider";

export enum CropStatusQuery {
    ENDED = "ENDED",
    ACTIVE = "ACTIVE",
    ALL = "ALL"
}

async function GetCropsProvider(authHeader: string, cropStatus: CropStatusQuery | undefined): Promise<Array<Crop>> {
    const query = {
        cropStatus: cropStatus || CropStatusQuery.ALL
    }
    const response = await apiClient(authHeader).get<Array<Crop>>(`/crops-full`, {params: query})
    return response.data
}

export default GetCropsProvider
