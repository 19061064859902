import Spraying from "../../../../model/spraying/Spraying";
import React, { useEffect, useState } from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useTranslation } from "react-i18next";
import SprayingProvider from "../../../../network/spraying/SprayingProvider";
import Paragraph from "antd/lib/typography/Paragraph";
import { InputNumber, Select, Spin } from "antd";
import SprayingAction from "../../../../model/spraying/SprayingAction";
import Dose from "../../../../model/Dose";

interface SprayingProps {
    onSprayingSelected: (sprayingAction: SprayingAction | null) => void
}

const SprayingView: React.FC<SprayingProps> = (props) => {
    const authHeader = useAuthHeader()!
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [sprayings, setSprayings] = useState<Spraying[]>([])
    const [sprayingsOptions, setOptionsSprayings] = useState<any[]>([])
    const [sprayingAction, setSprayingAction] = useState<SprayingAction | null>(null)
    const [dose, setDose] = useState<Dose>({
        amount: 0,
        unit: t('lPerHa')
    })
    const [spraying, setSpraying] = useState<Spraying | null>(null)

    const onDoseChange = (newAmount: number | null) => {
        setDose({
            ...dose,
            amount: newAmount || 0
        })
    }
    const onDoseUnitChange = (newDoseUnit: string) => {
        setDose({
            ...dose,
            unit: newDoseUnit
        })
    }

    useEffect(() => {
        props.onSprayingSelected
    }, [sprayingAction]);

    const unitSelect = (
        <Select defaultValue="l/Ha"
                style={{width: 100}}
                onChange={onDoseUnitChange}
        >
            <Select.Option value={t('mlPerHa')}>{t('mlPerHa')}</Select.Option>
            <Select.Option value={t('lPerHa')}>{t('lPerHa')}</Select.Option>
            <Select.Option value={t('gPerHa')}>{t('gPerHa')}</Select.Option>
            <Select.Option value={t('kgPerHa')}>{t('kgPerHa')}</Select.Option>
        </Select>
    )

    const loadSprayings = async () => {
        try {
            setIsLoading(true)
            const sprayingsResponse = await SprayingProvider(authHeader)
            setSprayings(sprayingsResponse)
            const options = sprayingsResponse.map((spraying) => {
                return {
                    value: spraying.id,
                    label: spraying.name,
                }
            })
            setOptionsSprayings(options)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        loadSprayings()

        return () => {
            setSprayings([])
            setOptionsSprayings([])
            props.onSprayingSelected(null)
        }
    }, []);

    const onSprayingSearch = (value: string) => {
        const spraying = sprayings.find((spraying) => spraying.id === value)
        setSpraying(spraying || null)
    }

    useEffect(() => {
        if (spraying === null) {
            props.onSprayingSelected(null)
            return
        }
        props.onSprayingSelected({
            dosePerHectare: dose,
            spraying: spraying
        })
    }, [dose, spraying]);

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        marginTop: 10,
        width: '100%',
    }}>

        <Paragraph style={{margin: 0}}>{t('mapFieldAddSprayingSelect')}</Paragraph>
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        }}>
            {isLoading && <div>
                <Spin/>
                <Paragraph style={{margin: 0, marginTop: 10}}>{t('loading')}</Paragraph>
            </div>
            }
            {!isLoading && <Select
                style={{
                    width: '100%',
                    textAlign: 'start',
                }}
                placeholder={t('mapFieldAddSprayingPlaceholder')}
                allowClear={true}
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                onChange={onSprayingSearch}
                options={sprayingsOptions}
            />}

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                }}>
                <Paragraph style={{
                    width: '100%',
                    textAlign: 'start',
                    margin: 0,
                    marginTop: 10
                }}>{t('dosePerHectare')}</Paragraph>
                <InputNumber
                    addonAfter={unitSelect}
                    onChange={onDoseChange}
                    defaultValue={0}
                    controls
                />
            </div>
        </div>
    </div>
}

export default SprayingView;
