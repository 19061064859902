import { Empty, message, Segmented, Table } from "antd";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Crop from "../../model/crop/Crop";
import GetCropsProvider, { CropStatusQuery } from "../../network/crop/GetCropsProvider";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { Spin } from "antd/lib";
import moment from "moment-timezone";
import { BeginType } from "../../model/cropcatalog/CropCatalog";
import useAnalyticsEventTracker, { gaPageViewTracker } from "../../utils/UseAnalyticsEventTracker";
import getAreaInHa from "../../utils/FieldUtils";

interface CurrentCropsProps {
    crops: Array<Crop>
}

function getSowingDate(crop: Crop, t: any): string {
    let text = ''
    switch (crop.cropCatalog.beginType) {
        case BeginType.SOWING:
            text = moment.utc(crop.createdAt).format('dddd, DD MMMM YYYY')
            break;
        case BeginType.PLANTING:
            text = moment.utc(crop.createdAt).format('dddd, DD MMMM YYYY')
            break;
    }
    return text
}


const CurrentCrops: React.FC<CurrentCropsProps> = ({crops}) => {
    const {t} = useTranslation()

    interface DataRow {
        key: number,
        title: string,
        regNumber: String,
        sowingDate: string,
        fieldArea: string,
    }

    const dataSources: Array<DataRow> = crops.map((item, index) => ({
        key: index,
        title: item.cropCatalog.title.pl,
        regNumber: item.field?.registrationNumber || '',
        sowingDate: getSowingDate(item, t),
        fieldArea: getAreaInHa((item.cropArea || 0 > 0 ? item.cropArea : undefined) || item.field?.area || 0, true, t),
    }))

    const columns = [{
        title: t('cropTableColumnCropName'),
        dataIndex: 'title',
        key: 'title',
    }, {
        title: t('cropTableColumnRegNumber'),
        dataIndex: 'regNumber',
        key: 'regNumber',
    }, {
        title: t('cropTableColumnSowingDate'),
        dataIndex: 'sowingDate',
        key: 'sowingDate',
    }, {
        title: t('cropTableColumnFieldArea'),
        dataIndex: 'fieldArea',
        key: 'fieldArea',
    }]

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                paddingTop: '20px',
                paddingBottom: '10px',
            }}>
            <Table style={{
                overflow: 'auto',
                height: '100%',
                width: '100%'
            }}
                   locale={{
                       emptyText: (
                           <Empty
                               image={Empty.PRESENTED_IMAGE_SIMPLE}
                               description={t('cropsCurrentEmpty')}
                           />
                       ),
                   }}
                   bordered={true}
                   dataSource={dataSources}
                   columns={columns}
                   pagination={false}
            />
        </div>
    )
}

interface HistoryCropsProps {
    crops: Array<Crop>
}

const HistoryCrops: React.FC<HistoryCropsProps> = ({crops}) => {
    const {t} = useTranslation()

    interface DataRow {
        key: number,
        title: string,
        regNumber: String,
        sowingDate: string,
        harvestingDate: string,
        fieldArea: string,
    }

    let dataSources: Array<DataRow> = crops.map((item, index) => ({
        key: index,
        title: item.cropCatalog.title.pl,
        regNumber: item.field?.registrationNumber || '',
        sowingDate: getSowingDate(item, t),
        harvestingDate: item.endedAt !== undefined ? moment.utc(item.endedAt).format('dddd, DD MMMM YYYY') : '',
        fieldArea: getAreaInHa((item.cropArea || 0 > 0 ? item.cropArea : undefined) || item.field?.area || 0, true, t),
    }))

    const columns = [{
        title: t('cropTableColumnCropName'),
        dataIndex: 'title',
        key: 'title',
    }, {
        title: t('cropTableColumnRegNumber'),
        dataIndex: 'regNumber',
        key: 'regNumber',
    }, {
        title: t('cropTableColumnSowingDate'),
        dataIndex: 'sowingDate',
        key: 'sowingDate',
    }, {
        title: t('cropTableColumnHarvestingDate'),
        dataIndex: 'harvestingDate',
        key: 'harvestingDate',
    }, {
        title: t('cropTableColumnFieldArea'),
        dataIndex: 'fieldArea',
        key: 'fieldArea',
    }]

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                paddingTop: '20px',
                paddingBottom: '10px',
            }}>
            <Table style={{
                overflow: 'auto',
                height: '100%',
                width: '100%'
            }}
                   bordered={true}
                   dataSource={dataSources}
                   columns={columns}
                   pagination={false}
            />
        </div>
    )
}

const Crops: React.FC = () => {
    gaPageViewTracker('Crops')
    const gaEventTracker = useAnalyticsEventTracker('Crops')
    const authHeader = useAuthHeader()!
    const {t} = useTranslation()
    const [messageApi, contextHolder] = message.useMessage()
    const [selectedSegment, setSelectedSegment] = useState<string>('current')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [currentCrops, setCurrentCrops] = useState<Array<Crop>>([])
    const [historyCrops, setHistoryCrops] = useState<Array<Crop>>([])
    moment.locale('pl')

    const loadActiveCrops = async () => {
        try {
            setIsLoading(true)
            const currentCrops = await GetCropsProvider(authHeader, CropStatusQuery.ACTIVE)
            setCurrentCrops(currentCrops)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            messageApi.error(t('cropsLoadingError'))
            console.log(e)
        }
    }

    const loadHistoryCrops = async () => {
        try {
            const currentCrops = await GetCropsProvider(authHeader, CropStatusQuery.ENDED)
            setHistoryCrops(currentCrops)
        } catch (e) {
            setIsLoading(false)
            messageApi.error(t('cropsLoadingError'))
            console.log(e)
        }
    }
    useEffect(() => {
            loadActiveCrops()
            loadHistoryCrops()
        }
        , []);

    const options = [
        {
            label: t('cropsCurrent'),
            value: 'current'
        },
        {
            label: t('cropsFinished'),
            value: 'history'
        }
    ]

    return (
        <div
            style={{
                position: "relative",
                display: 'flex',
                width: '100%',
                height: '100%',
            }}
            id={'crop-parent'}>
            {contextHolder}
            {isLoading &&
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Spin spinning={true} className={'crop-parent'}/>
                </div>
            }
            <div
                style={{
                    width: '100%',
                    padding: '20px',
                }}
            >
                <Segmented
                    onChange={(value) => {
                        gaEventTracker('segmentChanged', value.toString())
                        setSelectedSegment(value.toString())
                    }}
                    options={options}/>
                {selectedSegment === 'current' ? <CurrentCrops crops={currentCrops}/> :
                    <HistoryCrops crops={historyCrops}/>}
            </div>
        </div>
    )
}

export default Crops
