import './add_fields.css'
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, message, Modal, Spin } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import FieldSearchResultItem from "./field_search_result_item";
import { AddFieldHeader } from "./add_field_header";
import Field from "../../../model/field/field";
import { AddFieldProvider } from "../../../network/field/AddFieldProvider";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker, { gaPageViewTracker } from "../../../utils/UseAnalyticsEventTracker";

function addItemToArray(array: Array<Field>, newItems: Array<Field>): Array<Field> {
    const combinedArray = [...array, ...newItems]
    const uniqueItemsMap = new Map(combinedArray.map(item => [item.registrationNumber, item]));
    return Array.from(uniqueItemsMap.values());
}

const AddFields: React.FC = () => {
    gaPageViewTracker('add_fields')
    const gaEventTracker = useAnalyticsEventTracker('AddFields')
    const [messageApi, messageContextHolder] = message.useMessage();
    const authHeader = useAuthHeader()!
    const {t} = useTranslation()
    const [saveModal, setSaveModal] = useState(false);
    const [fields, setFields] = useState<Array<Field>>(Array());
    const [fullscreenLoader, setFullscreenLoader] = useState(false);
    const navigate = useNavigate()

    const onFieldDelete = (fieldRegNumber: string) => {
        gaEventTracker('fieldDelete')
        setFields(fields.filter((value) => value.registrationNumber !== fieldRegNumber))
    }

    const onSaveClick = () => {
        gaEventTracker('saveClick')
        setSaveModal(true)
    }

    const onNewFields = (newFields: Array<Field>) => {
        gaEventTracker('newFields')
        const newFields1 = addItemToArray(fields!, newFields)
        setFields(newFields1)
    }

    const onSaveOk = () => {
        gaEventTracker('saveOk')
        setSaveModal(false)
        setFullscreenLoader(true)
        AddFieldProvider(authHeader, fields).then(() => {
            navigate('/')
            setFullscreenLoader(false)
        })
    }

    const onSaveCancel = () => {
        gaEventTracker('saveCancel')
        setSaveModal(false)
    }

    return (
        <div className="AddFields">
            <div className="AddFields-Content">
                <Spin spinning={fullscreenLoader} fullscreen/>
                <AddFieldHeader
                    messageApi={messageApi}
                    messageContextHolder={messageContextHolder}
                    fieldsCount={fields.length}
                    saveButtonEnabled={fields.length > 0}
                    onSaveClick={onSaveClick}
                    setFullscreenLoader={setFullscreenLoader}
                    onFieldsFounded={onNewFields}
                />
                <Divider style={{marginTop: 0, marginBottom: 0}}/>

                <Modal
                    centered
                    open={saveModal}
                    onOk={onSaveOk}
                    onCancel={onSaveCancel}
                    okText={t('save')}
                    cancelText={t('cancel')}
                    title={t('fieldsAddSaveModalTitle', {count: fields.length})}
                    closeIcon={false}>
                    <Paragraph
                        style={{whiteSpace: 'pre-line'}}
                    >{t('fieldsAddSaveModalMessage')}</Paragraph>
                </Modal>
                <div style={{
                    overflow: 'auto',
                }}>

                    <ul style={{paddingInlineEnd: 40}}>
                        {fields.map((field) => {
                            return (
                                <FieldSearchResultItem
                                    field={field}
                                    onFieldDelete={onFieldDelete}
                                />
                            )
                        })}
                    </ul>
                </div>

                {fields.length === 0 ?
                    <div className={'AddFields-Empty'}>
                        <Paragraph
                            style={{whiteSpace: 'pre-line'}}>{t('fieldsAddSearchEmpty')}</Paragraph>
                    </div>
                    : <div/>}
            </div>
        </div>
    )
}

export default AddFields
