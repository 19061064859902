
export enum FieldAction {
    HARVESTING_DIGGING = 'HARVESTING_DIGGING',
    SOWING_PLANTING = 'SOWING_PLANTING',
    SPRAYING = 'SPRAYING',
    FERTILIZING = 'FERTILIZING',
    PLOWING = 'PLOWING',
    HARROWING = 'HARROWING',
    LIMING = 'LIMING',
    WATERING = 'WATERING',
}
