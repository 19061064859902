import React, { createContext, useState } from "react";
import Field from "../../model/field/field";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import GetFieldsService from "../../network/fields/GetFieldsService";
import { message } from "antd";
import { useTranslation } from "react-i18next";

interface FieldsManager {
    fields: Array<Field>
    isFieldLoading: boolean

    requestFieldsUpdate: () => void
    getFieldById: (id: string) => Field | null
    getNextFieldId: (id: string) => string | null
    getPrevFieldId: (id: string) => string | null
}

const FieldContext = createContext<FieldsManager | null>(null);

const FieldProvider: React.FC<any> = ({children}) => {
    const authHeader = useAuthHeader()!
    const [fields, setFields] = useState<Array<Field>>([])
    const [isFieldLoading, setIsFieldLoading] = useState<boolean>(false)
    const [messageApi, messageContextHolder] = message.useMessage();
    const {t} = useTranslation()

    const onFieldsUpdate = async () => {
        try {
            setIsFieldLoading(true)
            const fields = await GetFieldsService(authHeader)
            setFields(fields)
            setIsFieldLoading(false)
        } catch (e) {
            setIsFieldLoading(false)
            console.error(e)
            messageApi.error(t('errorGetFields'))
            setFields([])
        }
    }

    const getFieldById = (id: string): Field | null => {
        const field = fields.find(field => field.id === id)
        return field ? field : null
    }

    const getNextFieldId = (id: string): string | null => {
        const index = fields.findIndex(field => field.id === id)
        if (index === -1) {
            return null
        }
        return fields[index + 1].id
    }

    const getPrevFieldId = (id: string): string | null => {
        const index = fields.findIndex(field => field.id === id)
        if (index === -1) {
            return null
        }
        return fields[index - 1].id
    }

    return <FieldContext.Provider value={{
        fields: fields,
        isFieldLoading: isFieldLoading,

        requestFieldsUpdate: onFieldsUpdate,
        getFieldById: getFieldById,

        getNextFieldId: getNextFieldId,
        getPrevFieldId: getPrevFieldId
    }}>
        {messageContextHolder}
        {children}
    </FieldContext.Provider>
}

export function useFieldsManager(): FieldsManager {
    const context = React.useContext(FieldContext);
    if (context === null) {
        throw new Error('useAgroMap must be used within a AgroMapProvider');
    }
    return context;
}

export default FieldProvider;
