import { AxiosRequestConfig } from "axios";
import FieldSearchResult from "../../model/field_search_result";
import apiClient from "../network_provider";
import Field from "../../model/field/field";
import GeoField from "../../model/field/GeoField";

async function SearchByRegNumber(auth: string, regNumber: string): Promise<FieldSearchResult> {
    const requestConfig: AxiosRequestConfig = {
        params: {
            regNumber: regNumber
        }
    }
    const response= await apiClient(auth).get<FieldSearchResult>('/field/searchByRegNumber', requestConfig)
    return response.data
}

export async function AddFieldProvider(auth: string, fields: Array<Field>): Promise<void> {
    await apiClient(auth).put('/fields', fields)
}

export async function AddGeoFieldProvider(auth: string, fields: Array<GeoField>): Promise<void> {
    await apiClient(auth).put('/fields', fields)
}

export default SearchByRegNumber;
