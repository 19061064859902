import CropCatalog from "../../model/cropcatalog/CropCatalog";
import apiClient from "../network_provider";
import AddCropCatalog from "../../model/cropcatalog/AddCropCatalog";

async function CropsCatalogProvider(authToken: string): Promise<Array<CropCatalog>> {
    const response = await apiClient(authToken).get<Array<CropCatalog>>('/crops-catalog')
    return response.data
}

export async function AddCropCatalogProvider(authToken: string, addCropCatalog: AddCropCatalog): Promise<void> {
    const response = await apiClient(authToken).put('/crop-catalog', addCropCatalog)
    return response.data
}

export default CropsCatalogProvider
