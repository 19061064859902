import React, { PureComponent, useEffect, useRef, useState } from "react";
import GetCropStatsProvider from "../../network/crop/GetCropStatsProvider";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { CropStats } from "../../model/crop/CropStats";
import { Cell, Pie, PieChart, PieLabelRenderProps, Tooltip } from "recharts";
import { useTranslation } from "react-i18next";
import { Spin, Table, TableColumnsType } from "antd";
import useAnalyticsEventTracker, { gaPageViewTracker } from "../../utils/UseAnalyticsEventTracker";

const COLORS = [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042', // Original 4 colors
    '#845EC2', '#D65DB1', '#FF6F91', '#FF9671',
    '#FFC75F', '#F9F871', '#2C73D2', '#0081CF',
    '#0089BA', '#008E9B', '#008F7A', '#D65DB1',
    '#B39CD0', '#6D6875', '#C34A36', '#B0A8B9'
];

interface ChartData {
    name: string,
    area: number,
    color: string,
    percent: number
}

interface CropStatChartProps {
    chartData: ChartData[];
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel: React.FC<PieLabelRenderProps> = ({
                                                                  cx,
                                                                  cy,
                                                                  midAngle,
                                                                  innerRadius,
                                                                  outerRadius,
                                                                  percent,
                                                                  payload
                                                              }) => {
    const numericCx = +cx!;
    const numericCy = +cy!;
    const numericInnerRadius = +innerRadius!;
    const numericOuterRadius = +outerRadius!;
    const numericMidAngle = +midAngle!;

    if (percent === undefined) return null;

    const radius = numericInnerRadius + (numericOuterRadius - numericInnerRadius) * 0.5;
    const x = numericCx + radius * 1.65 * Math.cos(-numericMidAngle * RADIAN);
    const y = numericCy + radius * 1.65 * Math.sin(-numericMidAngle * RADIAN);

    return (
        <text x={x} y={y} fill="black" textAnchor={x > numericCx ? 'start' : 'end'} dominantBaseline="central">
            {`${payload.name}`} - {`${payload.area}ha`}
        </text>
    );
};

class CropStatChart extends PureComponent<CropStatChartProps> {
    render() {

        const {chartData} = this.props;
        return (
            <PieChart width={600} height={400}>
                <Pie
                    data={chartData}
                    cx={300}
                    cy={200}
                    innerRadius={60}
                    outerRadius={120}
                    fill={"#8884d8"}
                    paddingAngle={5}
                    dataKey="percent"
                    label={renderCustomizedLabel}
                >
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color}/>
                    ))}
                </Pie>
                <Tooltip/>
            </PieChart>
        )
    }
}

interface StatsTableProps {
    chartData: ChartData[]
}

const StatsTable: React.FC<StatsTableProps> = ({chartData}) => {
    gaPageViewTracker('CropStatsTable')
    const gaEventTracker = useAnalyticsEventTracker('CropStatsTable')
    const {t} = useTranslation()
    const [scrollHeight, setScrollHeight] = useState<number>();
    const divRef = useRef<HTMLDivElement>(null);
    const updateScrollHeight = () => {
        if (divRef.current) {
            setScrollHeight(divRef.current.clientHeight - 120);
        }
    };

    const columns: TableColumnsType<ChartData> = [{
        title: t('cropStatsTableColor'),
        dataIndex: 'color',
        key: 'color',
        width: 100,
        fixed: 'left',
        render: (color, string) => (
            <div style={{
                width: '20px',
                height: '20px',
                backgroundColor: color,
                margin: 'auto'
            }}/>
        )
    }, {
        title: t('cropStatsTableName'),
        dataIndex: 'name',
        key: 'name',
        width: 150,
        fixed: 'left'
    }, {
        title: t('cropStatsTableArea'),
        dataIndex: 'area',
        key: 'area',
        width: 150,
        fixed: 'left',
        render: (area, string) => (t('cropItemFieldArea', {area: area}))
    }, {
        title: t('cropStatsTablePercent'),
        dataIndex: 'percent',
        key: 'percent',
        width: 100,
        fixed: 'left',
        sorter: (a, b) => a.percent - b.percent,
        defaultSortOrder: 'descend',
        render: (percent, string) => (t('percentPlaceholder', {percent: percent}))
    }]

    useEffect(() => {
        updateScrollHeight(); // Update on mount

        // Add resize listener to update height on window resize
        window.addEventListener('resize', updateScrollHeight);

        // Cleanup listener on component unmount
        return () => window.removeEventListener('resize', updateScrollHeight);
    }, []);

    return (
        <div
            ref={divRef}
            id={'table-parent'}
            style={{
                display: "flex",
                height: 'calc(100vh - 100px)',
                flex: 1,
                overflow: 'hidden',
                width: 500,
                margin: 20,
            }}>
            <Table
                style={{
                    height: '100%'
                }}
                bordered
                columns={columns}
                scroll={{x: 400, y: scrollHeight}}
                pagination={false}
                dataSource={chartData}/>
        </div>
    )
}

function getAreaInHa(area: number): number {
    return +(area / 10000).toFixed(2)
}

const CropStatsView: React.FC = () => {
    const authHeader = useAuthHeader()!
    const [isLoading, setIsLoading] = useState<Boolean>(true)
    const [cropStats, setCropStats] = useState<CropStats>()
    const [chartData, setDataChart] = useState<Array<ChartData>>([])
    const {t} = useTranslation()
    const fetchStats = async () => {
        try {
            setIsLoading(true)
            const cropStats = await GetCropStatsProvider(authHeader)
            let fieldWithoutCropsInPercent = 100
            let fieldWithoutCropsInSquareMeters = cropStats.totalArea
            cropStats.cropStatList.forEach(cropStatsItem => {
                fieldWithoutCropsInPercent = fieldWithoutCropsInPercent - cropStatsItem.percentOfTotal
                fieldWithoutCropsInSquareMeters = fieldWithoutCropsInSquareMeters - cropStatsItem.area
            })
            const cropChartData: Array<ChartData> = cropStats.cropStatList.map((cropStatsItem, index) => (
                {
                    name: cropStatsItem.cropTitle.pl,
                    percent: +cropStatsItem.percentOfTotal.toFixed(1),
                    area: getAreaInHa(cropStatsItem.area),
                    color: COLORS[index % COLORS.length]
                }
            ))

            setCropStats(cropStats)
            cropChartData.push({
                name: t('cropStatsTableNoCrop'),
                percent: +fieldWithoutCropsInPercent.toFixed(1),
                area: getAreaInHa(fieldWithoutCropsInSquareMeters),
                color: '#888888'
            })
            setDataChart(cropChartData)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        fetchStats()
    }, []);

    console.log('isLOading:', isLoading)

    return <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            overflow: 'hidden'
        }}
        id={'stats'}
    >
        {!isLoading ?
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                flex: 'row',
                height: '100%'
            }}>
                <CropStatChart chartData={chartData}/>
                <StatsTable chartData={chartData}/>
            </div> : <Spin/>
        }

    </div>
}

export default CropStatsView
