import { FieldAction } from "../../model/field/FieldAction";
import FieldHistoryEntry from "../../model/field/FieldHistoryEntry";
import dayjs from "dayjs";
import Dose from "../../model/Dose";
import getAreaInHa from "../../utils/FieldUtils";
import 'dayjs/locale/pl';

dayjs.locale('pl');

function doseToString(dose: Dose) {
    return `${dose.amount}${dose.unit}`
}

export function getHistoryFieldAction(t: any) {
    const values = Object.values(FieldAction).map((action: FieldAction) => {
        return {
            value: action,
            label: t(action)
        }
    })

    return [{
        value: "ALL",
        label: t('fieldActionAll')
    }, ...values]
}

export interface HistoryFieldActionAllData {
    date: string,
    action: string,
    area: string,
    name: string,
    dose: string,
    performer: string,
    comment: string
}

export function getHistoryFieldAllActionData(t: any, historyEntries: Array<FieldHistoryEntry>): Array<HistoryFieldActionAllData> {

    return historyEntries.map((entry: FieldHistoryEntry) => {
        const dose = entry.sprayingAction?.dosePerHectare || entry.fertilizingAction?.dosePerHectare || entry.cropAction?.dosePerHectare
        let doseString = ''
        if (dose) {
            doseString = doseToString(dose)
        }
        const name = entry.sprayingAction?.spraying.name || entry.fertilizingAction?.fertilize.name || entry.cropAction?.cropCatalog.name || ''
        return {
            date: dayjs(entry.date).format('DD-MM-YYYY'),
            action: t(entry.action),
            area: getAreaInHa(entry.actionArea, true, t),
            dose: doseString,
            name: name,
            performer: entry.performer?.name || '',
            comment: entry.comment || ''
        }
    })
}

export function getHistoryFieldCropActionData(t: any, historyEntries: Array<FieldHistoryEntry>): Array<HistoryFieldActionAllData> {

    return historyEntries.map((entry: FieldHistoryEntry) => {
        const dose = entry.cropAction?.dosePerHectare
        let doseString = ''
        if (dose) {
            doseString = doseToString(dose)
        }
        const name = entry.cropAction?.cropCatalog.name || ''
        return {
            date: dayjs(entry.date).format('DD-MM-YYYY'),
            action: t(entry.action),
            area: getAreaInHa(entry.actionArea, true, t),
            dose: doseString,
            name: name,
            performer: entry.performer?.name || '',
            comment: entry.comment || ''
        }
    })
}

export function getHistoryFieldAllActionColumns(t: any) {
    return [
        {
            title: t('fieldActionDate'),
            dataIndex: 'date',
            key: 'date',
        }, {
            title: t('fieldActionType'),
            dataIndex: 'action',
            key: 'action',
        }, {
            title: t('fieldActionName'),
            dataIndex: 'name',
            key: 'name',
        }, {
            title: t('fieldActionArea'),
            dataIndex: 'area',
            key: 'area',
        }, {
            title: t('fieldActionDose'),
            dataIndex: 'dose',
            key: 'dose',
        }, {
            title: t('fieldActionPerformer'),
            dataIndex: 'performer',
            key: 'performer',
        }, {
            title: t('fieldActionComment'),
            dataIndex: 'comment',
            key: 'comment',
        }
    ]
}

export function getHistoryFieldSprayingActionColumns(t: any) {
    return [
        {
            title: t('fieldActionDate'),
            dataIndex: 'date',
            key: 'date',
        }, {
            title: t('fieldActionSprayingName'),
            dataIndex: 'name',
            key: 'name',
        }, {
            title: t('fieldActionArea'),
            dataIndex: 'area',
            key: 'area',
        }, {
            title: t('fieldActionDose'),
            dataIndex: 'dose',
            key: 'dose',
        }, {
            title: t('fieldActionPerformer'),
            dataIndex: 'performer',
            key: 'performer',
        }, {
            title: t('fieldActionComment'),
            dataIndex: 'comment',
            key: 'comment',
        }
    ]
}

export function getHistoryFieldFertilizingActionColumns(t: any) {
    return [
        {
            title: t('fieldActionDate'),
            dataIndex: 'date',
            key: 'date',
        }, {
            title: t('fieldActionFertilizingName'),
            dataIndex: 'name',
            key: 'name',
        }, {
            title: t('fieldActionArea'),
            dataIndex: 'area',
            key: 'area',
        }, {
            title: t('fieldActionDose'),
            dataIndex: 'dose',
            key: 'dose',
        }, {
            title: t('fieldActionPerformer'),
            dataIndex: 'performer',
            key: 'performer',
        }, {
            title: t('fieldActionComment'),
            dataIndex: 'comment',
            key: 'comment',
        }
    ]
}

export function getHistoryFieldCropActionColumns(t: any) {
    return [
        {
            title: t('fieldActionDate'),
            dataIndex: 'date',
            key: 'date',
        }, {
            title: t('fieldActionCropName'),
            dataIndex: 'name',
            key: 'name',
        }, {
            title: t('fieldActionCropArea'),
            dataIndex: 'area',
            key: 'area',
        }, {
            title: t('fieldActionDose'),
            dataIndex: 'dose',
            key: 'dose',
        }, {
            title: t('fieldActionPerformer'),
            dataIndex: 'performer',
            key: 'performer',
        }, {
            title: t('fieldActionComment'),
            dataIndex: 'comment',
            key: 'comment',
        }
    ]
}
