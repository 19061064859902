import Field from "../../model/field/field";
import React, { useCallback, useEffect, useState } from "react";
import 'leaflet/dist/leaflet.css';
import L, { CRS, LatLng, LatLngBounds, PathOptions } from 'leaflet';
import {
    ImageOverlay,
    MapContainer,
    Marker,
    Polygon,
    Popup,
    TileLayer,
    useMap,
    useMapEvents,
    WMSTileLayer
} from 'react-leaflet'
import SearchFieldByLatLngProvider from "../../network/field/SearchFieldByLatLngProvider";
import GeoField from "../../model/field/GeoField";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import getAreaInHa from "../../utils/FieldUtils";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import 'leaflet-geometryutil';
import { centerPosition, checkIfPointInPolygon, getMKOParams, polygons } from "./MapUtils";
import GetMKOFieldAreaProvider from "../../network/field/GetMKOFieldAreaProvider";
import proj4 from 'proj4';
import { CloseOutlined } from "@ant-design/icons";
import Coordinates from "../../model/coordinates";
import { AgroMapMode, useAgroMap } from "./AgroMapProvider";
import { useNavigate } from "react-router-dom";
import { fieldsToBoundingBox } from "../../utils/map/field_to_bounding_box";

const epsg2180def = "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
proj4.defs("EPSG:2180", epsg2180def);

const loaderIcon = new L.Icon({
    iconUrl: "https://media.tenor.com/G7LfW0O5qb8AAAAi/loading-gif.gif",
    iconSize: [25, 25], // Size of the icon
});

interface LocationMarkerProps {
    authHeader: string,
    isEditFieldEnabled: boolean
    newFieldPosition: LatLng | null
    onEditField: (geoField: GeoField) => void
    onAddField: (geoField: GeoField) => void,
    onPopupClose: () => void
}

const LocationMarker: React.FC<LocationMarkerProps> = (props) => {
    const {t} = useTranslation()
    const [position, setPosition] = useState<LatLng | null>(props.newFieldPosition)
    const [areaInHa, setAreaInHa] = useState<boolean>(true)
    const [geoField, setGeoField] = useState<GeoField | null>(null)
    const map = useMap()
    const agroMap = useAgroMap()

    const loadField = async () => {
        if (position) {
            try {
                setGeoField(null)
                const geoFieldPromise = SearchFieldByLatLngProvider(props.authHeader, position.lat, position.lng)
                const mkoAreaPromise = await GetMKOFieldAreaProvider(props.authHeader, getMKOParams(map, position))

                await Promise.all([geoFieldPromise, mkoAreaPromise])
                    .then(([geoField, mkoArea]) => {
                        setPosition(null)
                        if (geoField !== null) {
                            setGeoField({...geoField, mkoArea: mkoArea})
                        }
                    })
            } catch (e) {
                console.log(e)
            }
        }
    }

    useEffect(() => {
        loadField()
    }, [position])

    const polygonFieldOptions = {
        color: 'gray',
        fillColor: '#3f3f3f',
        fillOpacity: 0.2,
        weight: 0.5
    }

    return <div>
        {position === null ? null : (<Marker position={position} icon={loaderIcon}/>)}
        {geoField === null ? null : (<div>
            <Polygon positions={polygons(geoField.boundaries)} pathOptions={polygonFieldOptions}/>
            <Popup
                closeButton={false}
                closeOnClick={false}
                position={centerPosition(geoField)}>

                <div
                    style={{}}
                >
                    <CloseOutlined
                        onClick={(e) => {
                            e.stopPropagation()
                            props.onPopupClose()
                            setGeoField(null)
                        }
                        }
                        style={{
                            position: 'absolute',
                            right: 10,
                        }}
                    />
                    <Paragraph style={{margin: 0}}>{t('mapFieldDrawerFieldRegNumber')}</Paragraph>
                    <Paragraph style={{margin: 0, fontWeight: "bold"}}>{geoField.registrationNumber}</Paragraph>

                    <Paragraph style={{margin: 0, marginTop: 10}}>{t('fieldMapPopupArea')}</Paragraph>
                    <Paragraph
                        onClick={() => setAreaInHa(!areaInHa)}
                        style={{
                            cursor: 'pointer',
                            margin: 0,
                            fontWeight: "bold"
                        }}>{getAreaInHa(geoField.area, areaInHa, t)}</Paragraph>

                    {geoField.mkoArea !== null && <div>

                        <Paragraph style={{margin: 0, marginTop: 10}}>{t('fieldMapPopupMKOArea')}</Paragraph>
                        <Paragraph
                            onClick={() => setAreaInHa(!areaInHa)}
                            style={{
                                cursor: 'pointer',
                                margin: 0,
                                fontWeight: "bold"
                            }}>{getAreaInHa(geoField.mkoArea, areaInHa, t)}</Paragraph>
                    </div>}
                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 30}}>
                        <Button
                            style={{marginLeft: 0}}
                            onClick={(e) => {
                                map.stop()
                                e.stopPropagation()
                                setPosition(null)
                                setGeoField(null)
                                props.onAddField(geoField)
                            }
                            }
                            type={"primary"}>{t('addFieldButton')}</Button>
                        <Button
                            style={{marginLeft: 10}}
                            onClick={(e) => {
                                map.stop()
                                e.stopPropagation()
                                agroMap.setEditField(geoField)
                                setPosition(null)
                                setGeoField(null)
                            }}
                        >{t('editFieldButton')}</Button>
                    </div>
                </div>
            </Popup>
        </div>)}
    </div>
}

interface FieldState {
    field: Field,
    isSelected: boolean
}

interface FieldPolygonProps {
    fieldState: FieldState
}

const FieldPolygonView: React.FC<FieldPolygonProps> = (props) => {
    const {t} = useTranslation()
    const agroMap = useAgroMap()
    const navigate = useNavigate()

    let polygonFieldOptions: PathOptions

    if (agroMap.mode !== AgroMapMode.FIELD_ADD) {
        polygonFieldOptions = {
            color: 'gray',
            fillColor: props.fieldState.field.crop?.cropTypeColor || '#9f9f9f',
            fillOpacity: 0.8,
            weight: 0.5
        }
    } else if (props.fieldState.isSelected) {
        polygonFieldOptions = {
            color: 'gray',
            fillColor: props.fieldState.field.crop?.cropTypeColor || '#9f9f9f',
            fillOpacity: 1,
            weight: 2
        }
    } else {
        polygonFieldOptions = {
            color: 'gray',
            fillColor: props.fieldState.field.crop?.cropTypeColor || '#9f9f9f',
            fillOpacity: 0.2,
            weight: 2
        }
    }

    const markerIcon = L.divIcon({
        html: props.fieldState.field.registrationNumber.split(".")[2],
        className: '',
    });

    const startActionForField = () => {
        agroMap.setMode(AgroMapMode.FIELD_ADD)
        agroMap.onFieldSelected(props.fieldState.field)
    }

    const openFieldDetails = () => {
        navigate(`/field/${props.fieldState.field.id}`)
    }

    return <div>
        <Polygon
            key={props.fieldState.field.fieldName}
            positions={polygons(props.fieldState.field.boundaries)} pathOptions={polygonFieldOptions}/>

        <Marker
            position={centerPosition(props.fieldState.field)}
            icon={markerIcon}
        />
        {props.fieldState.isSelected && agroMap.mode === null && <Popup
            key={props.fieldState.field.fieldName}
            position={centerPosition(props.fieldState.field)}
        >
            <div>
                <Paragraph
                    style={{
                        margin: 0,
                        marginTop: 10,
                    }}
                >{t('fieldName')}</Paragraph>
                <Paragraph style={{
                    margin: 0,
                    fontWeight: "bold",
                }}>{props.fieldState.field.fieldName}</Paragraph>
                <Paragraph
                    style={{
                        margin: 0,
                        marginTop: 10,
                    }}
                >{t('fieldArea')}</Paragraph>
                <Paragraph style={{
                    margin: 0,
                    fontWeight: "bold",
                }}>{getAreaInHa(props.fieldState.field.area, true, t)}</Paragraph>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 10,
                        flexDirection: 'row',
                    }}
                >
                    <Button
                        style={{
                            width: 120,
                            marginRight: 10
                        }}
                        onClick={startActionForField}
                        type={"primary"}
                    >{t('addAction')}</Button>
                    <Button
                        style={{
                            width: 120
                        }}
                        onClick={openFieldDetails}
                        type={"primary"}
                    >{t('fieldCard')}</Button>
                </div>
            </div>
        </Popup>}
    </div>
}

interface MapViewWrapperProps {
    onMapClick?: (latLng: LatLng) => void
}

const MapViewWrapper: React.FC<MapViewWrapperProps> = (props) => {
    const map = useMap()
    const agrMap = useAgroMap()
    useEffect(() => {
        agrMap.setMap(map)
    }, [map]);

    useMapEvents({
        click(e) {
            props.onMapClick && props.onMapClick(e.latlng)
        }
    })

    return null
}


const wmsOptions = {
    crs: CRS.EPSG4326
}

const params = {
    layers: '02,04,06,08,10,12,14,16,18,20,22,24,26,28,30,32',
    format: 'image/png',
    transparent: true,
    version: '1.1.1',
    width: 256,
    height: 256,
    service: 'WMS',
    request: 'GetMap'
}

interface AgroMapViewProps {
    fields?: Array<Field>,
    onAddGeoField?: (geoField: GeoField) => void,
}

interface UrlBuilderParams {
    baseUrl: string;
    path?: string;
    queryParams?: Record<string, string | number | boolean>;
}

const buildUrl = ({baseUrl, path = "", queryParams = {}}: UrlBuilderParams): string => {
    const url = new URL(path, baseUrl);
    Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.append(key, String(value));
    });
    return url.toString();
};


// todo fix this
const FieldNumbersLayer: React.FC = () => {
    const [url1, setUrl1] = useState<string | null>(null)
    const [params, setParams] = useState<any>(null)
    const map = useMap()
    useMapEvents({
        moveend(e) {

            const params = getMKOParams(map, map.getCenter())
            const queryParams = {
                request: 'GetMap',
                service: 'WMS',
                version: '1.1.1',
                layers: 'numery_dzialek',
                format: 'image/png',
                transparent: true,
                width: params.width,
                height: params.height,
                bbox: params.bbox,
                // bbox: '674195.513073929,483561.453066365,674312.526927038,483614.826927763',
                srs: 'EPSG:2180',
            }
            const urlBuilder: UrlBuilderParams = ({
                baseUrl: 'https://integracja02.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow',
                queryParams: queryParams
            })

            setParams(queryParams)
            const url = buildUrl(urlBuilder)
            setUrl1(url)
        }
    })

    console.log("URL: ", url1)

    return <div>
        {url1 === null ? null : <ImageOverlay
            url={url1}
            bounds={map.getBounds()}
        />}
    </div>
}

const AgroMapView: React.FC<AgroMapViewProps> = (props) => {
    const authHeader = useAuthHeader()!
    const [fieldsState, setFieldsState] = useState<FieldState[]>(props.fields?.map((field) => ({
        field: field,
        isSelected: false
    })) || [])
    const [newFieldPosition, setNewFieldPosition] = useState<LatLng | null>(null)
    const agroMap = useAgroMap()
    const [mapBounds, setMapBounds] = useState<LatLngBounds>(fieldsToBoundingBox(props.fields || []))

    useEffect(() => {
        const map = agroMap.map
        if (map) {
            console.log("Map bounds: ", mapBounds)
            map.fitBounds(mapBounds)
        }
    }, [mapBounds]);

    useEffect(() => {
        setFieldsState(fieldsState.map(fieldState => {
            return ({
                ...fieldState,
                isSelected: agroMap.selectedFields.includes(fieldState.field)
            })
        }))
    }, [agroMap.selectedFields]);


    useEffect(() => {
        if (agroMap.mode === null) {
            setNewFieldPosition(null)
            setFieldsState(fieldsState.map(fieldState => ({...fieldState, isSelected: false})))
        }
    }, [agroMap.mode]);

    const onAddFieldCallback = useCallback((geoField: GeoField) => {
        if (props.onAddGeoField !== undefined) {
            props.onAddGeoField(geoField)
            setNewFieldPosition(null)
        }
    }, [])


    const onEditFieldCallback = useCallback((geoField: GeoField) => {
        setNewFieldPosition(null)
    }, [])

    const onNewFieldPopupClosed = useCallback(() => {
        agroMap.setMode(null)
        setNewFieldPosition(null)
    }, [])

    useEffect(() => {
        setFieldsState(props.fields?.map((field) => ({
            field: field,
            isSelected: false
        })) || [])
        setMapBounds(fieldsToBoundingBox(props.fields || []))
    }, [props.fields]);

    const onMapClick = (latLng: LatLng) => {
        const coords: Coordinates = {
            latitude: latLng.lat,
            longitude: latLng.lng
        }

        let isSomeFieldSelected = false;
        if (fieldsState) {

            const updatedFields = fieldsState.map(fieldState => {
                let isSelected = fieldState.isSelected
                if (agroMap.mode === AgroMapMode.FIELD_ADD) {
                    if (checkIfPointInPolygon(coords, fieldState.field.boundaries)) {
                        isSelected = !fieldState.isSelected
                        if (isSelected) {
                            agroMap.onFieldSelected(fieldState.field)
                        } else {
                            agroMap.onFieldUnselected(fieldState.field)
                        }
                    }
                } else {
                    isSelected = checkIfPointInPolygon(coords, fieldState.field.boundaries)
                }
                if (isSelected) {
                    isSomeFieldSelected = true;
                }
                return ({
                    ...fieldState,
                    isSelected: isSelected
                })
            });

            setFieldsState(updatedFields);

            let zoom = 0
            if (agroMap.map) {
                zoom = agroMap.map.getZoom()
            }

            if (!isSomeFieldSelected && agroMap.mode === null && zoom >= 16) {
                setNewFieldPosition(latLng)
                agroMap.setMode(AgroMapMode.FIELD_EDIT)
            }
        }
    }

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: '100%',
        }}>
            <MapContainer
                bounds={mapBounds}
                style={{
                    width: '100%',
                    height: '100%',
                    zIndex: 0
                }}
            >
                <MapViewWrapper onMapClick={onMapClick}/>
                <TileLayer
                    // @ts-ignore
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {agroMap.mapUI.showSatellite && <TileLayer
                    url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                    maxZoom={20}
                    subdomains={['mt1', 'mt2', 'mt3']}
                />}
                {agroMap.mapUI.showMKOArea && <WMSTileLayer
                    url={'https://mapy.geoportal.gov.pl/wss/ext/arimr_mko'}
                    params={params}
                    {...wmsOptions}
                />}
                {agroMap.mapUI.showFieldNumbers && <FieldNumbersLayer/>}

                {props.onAddGeoField === undefined || newFieldPosition === null ? null :
                    <LocationMarker
                        authHeader={authHeader}
                        isEditFieldEnabled={true}
                        onAddField={onAddFieldCallback}
                        newFieldPosition={newFieldPosition}
                        onPopupClose={onNewFieldPopupClosed}
                        onEditField={onEditFieldCallback}/>
                }

                {fieldsState.map((fieldState, index) => <FieldPolygonView
                    key={`${fieldState.field.fieldName}_${index}`}
                    fieldState={fieldState}/>)}
            </MapContainer>
        </div>
    )
}

export default AgroMapView;
