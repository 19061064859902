export interface SimpleCropCatalog {
    id: string,
    name: string,
    type: CropType,
}

export default interface CropCatalog {
    id: string,
    name: string,
    beginType: BeginType,
    endType: EndType,
    title: Title
    type: CropType,
}

export interface Title {
    pl: string,
}

export enum BeginType {
    SOWING = "SOWING",
    NONE = "NONE",
    PLANTING = "PLANTING",
}

export enum EndType {
    HARVESTING = "HARVESTING",
    DIGGING = "DIGGING",
}

export enum CropType {
    GRAIN = "GRAIN",
    ROOT_AND_TUBER_VEGETABLE = "ROOT_AND_TUBER_VEGETABLE",
    HEAD_OR_FLOWER_VEGETABLE = "HEAD_OR_FLOWER_VEGETABLE",
    OILSEED = "OILSEED",
    FRUITS = "FRUITS",
}

export function GetCropBeginType(crop: CropType): BeginType {
    switch (crop) {
        case CropType.GRAIN:
            return BeginType.SOWING
        case CropType.ROOT_AND_TUBER_VEGETABLE:
            return BeginType.SOWING
        case CropType.HEAD_OR_FLOWER_VEGETABLE:
            return BeginType.SOWING
        case CropType.OILSEED:
            return BeginType.SOWING
        case CropType.FRUITS:
            return BeginType.NONE
    }
}

export function GetCropEndType(crop: CropType): EndType {
    switch (crop) {
        case CropType.GRAIN:
            return EndType.HARVESTING
        case CropType.ROOT_AND_TUBER_VEGETABLE:
            return EndType.DIGGING
        case CropType.HEAD_OR_FLOWER_VEGETABLE:
            return EndType.HARVESTING
        case CropType.OILSEED:
            return EndType.HARVESTING
        case CropType.FRUITS:
            return EndType.HARVESTING
    }
}
