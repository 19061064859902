import './header.css'
import { Avatar, Badge, Button, Divider, Dropdown, MenuProps, Space } from "antd";
import { BellFilled, MenuOutlined, UserOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { SessionAppContext } from "../../../utils/session_app_state_provider";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import { useNavigate } from "react-router-dom";
import useSignOut from "react-auth-kit/hooks/useSignOut";


interface HeaderViewProps {
    showMenuButton?: boolean
    onMenuButtonClick?: () => void
}

const HeaderView: React.FC<HeaderViewProps> = (props) => {
    const {sessionAppState} = useContext(SessionAppContext)!
    const {t} = useTranslation()
    const notifications = 0
    const navigate = useNavigate()
    const signOut = useSignOut()
    const logout = () => {
        signOut()
        navigate('/login')
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a onClick={logout}>{t('headerMenuLogout')}
                </a>
            ),
        }
    ]

    return (
        <div className={'Header'}>
            <div className={'Header-Content'}>
                {sessionAppState.farm?.name !== undefined ?
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: 'auto',
                        }}>
                        {props.showMenuButton &&
                            <MenuOutlined style={{marginRight: 20}} onClick={props.onMenuButtonClick}/>}
                        <Paragraph style={{margin: 0}}>{sessionAppState.farm?.name}</Paragraph>
                    </div>
                    : <div></div>}

                <Space align="center">
                    <Badge count={notifications} size={'small'}>
                        <BellFilled/>
                    </Badge>
                    <span className={'Header-Title'}>
                        {t('headerTitle', {name: sessionAppState.user?.firstName})}
                    </span>
                    <Dropdown menu={{items}} placement={'bottomRight'}>
                        <Avatar className={'Header-Avatar'}>
                            <UserOutlined/>
                        </Avatar>
                    </Dropdown>
                </Space>
            </div>
            <Divider style={{margin: 0}}/>
        </div>
    )
}

export default HeaderView
