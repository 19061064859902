import React, { createContext, useState } from "react";
import GeoField from "../../model/field/GeoField";
import Field from "../../model/field/field";

export enum AgroMapMode {
    FIELD_EDIT = 'FIELD_EDIT',
    FIELD_ADD = 'FIELD_ADD',
}

interface AgroMapUI {
    showMKOArea: boolean,
    showSatellite: boolean,
    showFieldNumbers: boolean,
}

interface AgroMap {
    map?: L.Map,
    setMap: (map: L.Map) => void,

    mapUI: AgroMapUI,
    setMapUI: (ui: AgroMapUI) => void,

    mode: AgroMapMode | null,
    setMode: (mode: AgroMapMode | null) => void,

    selectedFields: Field[],
    onFieldSelected: (field: Field) => void,
    onFieldUnselected: (field: Field) => void,

    editField: GeoField | null,
    setEditField: (field: GeoField | null) => void
}

const AgroMapContext = createContext<AgroMap | null>(null);

const AgroMapProvider: React.FC<any> = ({children}) => {
    const [map, setMap] = useState<L.Map | undefined>(undefined);
    const [mapUI, setMapUI] = useState<AgroMapUI>(({
        showMKOArea: true,
        showSatellite: false,
        showFieldNumbers: false,
    }));
    const [selectedFields, setSelectedFields] = useState<Field[]>([]);
    const [mapMode, setMapMode] = useState<AgroMapMode | null>(null);
    const [editField, setEditField] = useState<GeoField | null>(null);

    const setMapCallback = (map: L.Map) => {
        setMap(map);
    }

    const setMapUICallback = (newState: AgroMapUI) => {
        setMapUI(newState);
    }

    const setModeCallback = (mode: AgroMapMode | null) => {
        if (mapMode === AgroMapMode.FIELD_ADD) {
            setSelectedFields([]);
        }

        if (mode === AgroMapMode.FIELD_EDIT) {
            setEditField(null);
        }

        setMapMode(mode);
    }

    const onFieldSelected = (field: Field) => {
        setSelectedFields([...selectedFields, field]);
    }

    const onFieldUnselected = (field: Field) => {
        setSelectedFields(selectedFields.filter(f => f.id !== field.id));
    }

    const setEditFieldCallback = (field: GeoField | null) => {
        setEditField(field);
        setMapMode(field ? AgroMapMode.FIELD_EDIT : null)
    }

    return (
        <AgroMapContext.Provider value={{
            map: map,
            setMap: setMapCallback,

            mapUI: mapUI,
            setMapUI: setMapUICallback,

            selectedFields: selectedFields,
            onFieldSelected: onFieldSelected,
            onFieldUnselected: onFieldUnselected,

            mode: mapMode,
            setMode: setModeCallback,

            editField: editField,
            setEditField: setEditFieldCallback
        }}>
            {children}
        </AgroMapContext.Provider>
    )
}

export function useAgroMap(): AgroMap {
    const context = React.useContext(AgroMapContext);
    if (context === null) {
        throw new Error('useAgroMap must be used within a AgroMapProvider');
    }
    return context;
}

export default AgroMapProvider;
