import React, { useEffect, useRef, useState } from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { SprayingsFullProvider } from "../../network/spraying/SprayingProvider";
import { Input, Modal, Spin, Table } from "antd";
import SprayingFull from "../../model/spraying/SprayingFull";
import { useTranslation } from "react-i18next";
import { Size } from "../../utils/UseElementSize";
import useResizeObserver from "@react-hook/resize-observer";
import type { TableColumnsType, TableProps } from 'antd';
import TitleItemView from "../views/TitleItemView";

interface SearchViewProps {
    onSearch?: (value: string) => void
    onClear?: () => void
}

const SearchView: React.FC<SearchViewProps> = (props) => {
    const onChange = (e: string) => {
        if (e.length !== 0 && props.onSearch) {
            props.onSearch(e)
        } else {
            props.onClear && props.onClear()
        }
    }

    return <div
        style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: 250,
        }}
    >
        <Input
            placeholder={"Wyszukaj..."}
            onChange={(e) => onChange(e.target.value)}
            allowClear
        />
    </div>
}

const SprayingDetailsView: React.FC<{ spraying: SprayingFull }> = ({spraying}) => {
    const {t} = useTranslation()
    return <div>
        <TitleItemView title={t('sprayingName')} label={spraying.name}/>
        <TitleItemView title={t('sprayingPermitNumber')} label={spraying.permitNumber}/>
        <TitleItemView title={t('sprayingType')} label={spraying.type}/>
        <TitleItemView title={t('sprayingAddressOfCompany')} label={spraying.addressOfCompany}/>
        <TitleItemView title={t('sprayingNameOfCompany')} label={spraying.nameOfCompany}/>
        <TitleItemView title={t('sprayingActiveSubstance')} label={spraying.activeSubstance}/>
        <TitleItemView title={t('sprayingClassificationOfPlantProtectionForHuman')}
                       label={spraying.classificationOfPlantProtectionForHuman || 'brak'}/>
        <TitleItemView title={t('sprayingClassificationOfPlantProtectionForBees')}
                       label={spraying.classificationOfPlantProtectionForBees || 'brak'}/>
        <TitleItemView title={t('sprayingClassificationOfPlantProtectionForWaterOrganism')}
                       label={spraying.classificationOfPlantProtectionForWaterOrganism || 'brak'}/>
        <TitleItemView title={t('sprayingClassificationOfPlantProtectionForNaturalEnvironment')}
                       label={spraying.classificationOfPlantProtectionForNaturalEnvironment || 'brak'}/>
        <TitleItemView title={t('sprayingPermitDueDate')} label={spraying.permitDueDate}/>
        <TitleItemView title={t('sprayingUsingUpPeriodForSales')} label={spraying.usingUpPeriodForSales}/>
        <TitleItemView title={t('sprayingUsingUpPeriodForStorage')} label={spraying.usingUpPeriodForStorage}/>
        <TitleItemView title={t('sprayingTypeOfPacking')} label={spraying.typeOfPacking || 'brak'}/>
        <TitleItemView title={t('sprayingNameOfReferenceProduct')} label={spraying.nameOfReferenceProduct || 'brak'}/>
        <TitleItemView title={t('sprayingComments')} label={spraying.comments || 'brak'}/>
    </div>
}

function columns(t: any) {
    const columns: TableColumnsType<SprayingFull> = [
        {
            title: t('sprayingSimpleName'),
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },
        {
            title: t('sprayingSimplePermitNumber'),
            dataIndex: 'permitNumber',
            width: 200,
            key: 'permitNumber',
        },
        {
            title: t('sprayingType'),
            dataIndex: 'type',
            width: 200,
            key: 'type',
        },
        {
            title: t('sprayingNameOfCompany'),
            dataIndex: 'nameOfCompany',
            width: 300,
            key: 'nameOfCompany',
        },
        {
            title: t('sprayingSimpleActiveSubstance'),
            dataIndex: 'activeSubstance',
            width: 300,
            key: 'activeSubstance',
        },
    ]
    return columns
}

const SprayingsView: React.FC = () => {
    const {t} = useTranslation()
    const authHeader = useAuthHeader()!
    const [sprayings, setSprayings] = useState<SprayingFull[]>([])
    const [originalSprayings, setOriginalSprayings] = useState<SprayingFull[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const parentRef = useRef<HTMLDivElement>(null)
    const [size, setSize] = useState<Size>({width: 0, height: 0})
    const [selectedSpraying, setSelectedSpraying] = useState<SprayingFull | undefined>(undefined)
    const setRoundedSize = ({width, height}: Size) => {
        setSize({width: Math.round(width), height: Math.round(height)});
    };

    useResizeObserver(parentRef, entry => {
        const {inlineSize: width, blockSize: height} = entry.contentBoxSize[0];
        setRoundedSize({width, height});
    });

    const loadSprayings = async () => {
        try {
            setLoading(true)
            const sprayings = await SprayingsFullProvider(authHeader)
            setSprayings(sprayings)
            setOriginalSprayings(sprayings)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        loadSprayings()
    }, []);

    const onSearch = (value: string) => {
        const filtered = originalSprayings.filter(s => s.name.toLowerCase().includes(value.toLowerCase()))
        setSprayings(filtered)
    }

    const onSearchClear = () => {
        setSprayings(originalSprayings)
    }

    const onDetailsClose = () => {
        setSelectedSpraying(undefined)
    }
    const header = (
        <div>
            <SearchView onSearch={onSearch} onClear={onSearchClear}/>
        </div>
    )

    return <div
        ref={parentRef}
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
        }}>
        {loading && <Spin/>}
        {selectedSpraying && <Modal
            onOk={onDetailsClose}
            onCancel={onDetailsClose}
            width={size.width - 100}
            open><SprayingDetailsView spraying={selectedSpraying}/></Modal>}
        <Table
            style={{
                width: '100%',
                height: '100%',
            }}
            title={() => header}
            showHeader={true}
            columns={columns(t)}
            onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        setSelectedSpraying(record)
                    }
                }
            }}
            pagination={{
                pageSize: 100,
                disabled: false,
            }}
            dataSource={sprayings}
            scroll={{
                y: size.height - 180,
            }}
            bordered
        />
    </div>
}

export default SprayingsView;
