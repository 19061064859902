import { MessageInstance } from "antd/es/message/interface";
import React, { ChangeEvent, useEffect, useState } from "react";
import FieldSearchResult from "../../../model/field_search_result";
import { useTranslation } from "react-i18next";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import SearchByRegNumber from "../../../network/field/AddFieldProvider";
import AddFieldsByImage from "../../../network/add_fields_by_image_provider";
import FieldSearchResultModal from "./field_search_result_modal";
import { Button, Space } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Search from "antd/lib/input/Search";
import Upload from "antd/lib/upload/Upload";
import { UploadOutlined } from "@ant-design/icons";
import './add_fields.css'
import Field from "../../../model/field/field";
import GenerateStaticMapProvider from "../../../network/generate_static_map_provider";
import { getStaticMapParams } from "../../../utils/map/static_map_param";

function validateFieldRegNumber(newValue: string): boolean {
    const locationTypePrecinctPlotPattern = /^\d{6}_\d{1}.\d{4}.\d{1,4}(\/\d{1,3})?$/
    return locationTypePrecinctPlotPattern.test(newValue)
}

function convertToField(fieldSearchResult: FieldSearchResult, staticMapImageName: string): Field {
    return {
        id: "",
        registrationNumber: fieldSearchResult.registrationNumber,
        fieldName: fieldSearchResult.registrationNumber,
        label: fieldSearchResult.label,
        area: fieldSearchResult.area,
        mkoArea: fieldSearchResult.area,
        center: fieldSearchResult.center,
        boundaries: [fieldSearchResult.boundary],
        crop: null,
    }
}

export function AddFieldHeader(props: {
    messageApi: MessageInstance,
    messageContextHolder: React.ReactNode,
    saveButtonEnabled: boolean,
    fieldsCount: number,
    onSaveClick: () => void,
    setFullscreenLoader: (value: boolean) => void,
    onFieldsFounded: (newFields: Array<Field>) => void | undefined,
}) {
    const {t} = useTranslation()
    const authHeader = useAuthHeader()!
    const [searching, setSearching] = useState(false);
    const [singleSearch, setSingleSearch] = useState(false);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
    const [searchResult, setSearchResult] = useState<Array<Field> | undefined>(undefined);
    const [searchInputStatus, setSearchInputStatus] = useState<'error' | ''>('');
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        setSearchResult(undefined)
    }, [])

    useEffect(() => {
        setSaveButtonEnabled(saveButtonEnabled)
    }, [saveButtonEnabled]);

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {value: inputValue} = e.target;
        if (validateFieldRegNumber(inputValue)) {
            setSearchInputStatus('')
        } else {
            setSearchInputStatus('error')
        }
        setSearchValue(inputValue)
    }

    const onSearch = async (value: string) => {
        if (validateFieldRegNumber(value)) {
            setSearching(true)
            setSingleSearch(true)
            try {
                const result = await SearchByRegNumber(authHeader, value)
                const staticMapImageName = await GenerateStaticMapProvider(authHeader, getStaticMapParams(result.boundary))

                setSearching(false)
                setSearchResult(Array(convertToField(result, staticMapImageName)))

            } catch (e) {
                console.log(e)
                setSearching(false)
                props.messageApi.open({
                    type: 'error',
                    content: t('fieldsAddFieldNotFound'),
                });
            }
        } else if (value.length !== 0) {
            props.messageApi.open({
                type: 'error',
                content: t('fieldsAddWrongRegNumber'),
            });
        }
    }
    const handleUpload = async (options: any) => {
        const {onSuccess, onError, file, onProgress} = options;
        try {
            props.setFullscreenLoader(true)
            const result = await AddFieldsByImage(authHeader, file, onProgress)

            const fields = await Promise.all(result.map(async (resultItem) => {
                const staticMapImageName = await GenerateStaticMapProvider(authHeader, getStaticMapParams(resultItem.boundary));
                return convertToField(resultItem, staticMapImageName);
            }));

            if (result.length > 0) {
                setSearchResult(fields)
            } else {
                props.messageApi.open({
                    type: 'error',
                    content: t('fieldsAddFieldNotFound'),
                });
            }
            props.setFullscreenLoader(false)
            onSuccess()
        } catch (e) {
            props.setFullscreenLoader(false)
            console.log(e)
            onError(e)
        }
    }

    const onSearchResultAccept = (newFields: Array<Field> | undefined) => {
        if (searchResult !== undefined) {
            props.onFieldsFounded(searchResult)
            setSearchResult(undefined)
        }
    }

    return (
        <div className="AddFields-Header">
            <FieldSearchResultModal
                fieldsSearchResult={searchResult}
                onAccept={onSearchResultAccept}
                singleSearchResult={singleSearch}
            />
            <div className={'AddFields-Header-Top'}>
                <Space>
                    <div/>
                </Space>
                <Space className="AddFields-Search">
                    {props.messageContextHolder}
                    <Paragraph style={{margin: 0}}>{t('fieldsAddInsertRegNumber')}</Paragraph>
                    <Search
                        status={searchInputStatus}
                        value={searchValue}
                        onChange={onSearchChange}
                        onSearch={onSearch}
                        enterButton={t('fieldsAddSearchButton')}
                        placeholder={t('fieldsAddExampleRegNumber')}
                        allowClear
                        loading={searching}
                    />
                    <Paragraph style={{margin: 0}}>{t('or')}</Paragraph>

                    <Upload
                        customRequest={handleUpload}
                        showUploadList={false}
                    >
                        <Button
                            icon={<UploadOutlined/>}
                            type={'primary'}>{t('fieldsAddUploadFile')}</Button>
                    </Upload>
                </Space>
                <Space>
                    <Button
                        onClick={props.onSaveClick}
                        disabled={!props.saveButtonEnabled}
                        type={'primary'}>{t('fieldsAddSave')}
                    </Button>
                </Space>
            </div>
            <Paragraph>{t('fieldsAddFounded', {count: props.fieldsCount})} </Paragraph>
        </div>
    )
}

export default AddFieldHeader