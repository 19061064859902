import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import GeoField from "../../../model/field/GeoField";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import getAreaInHa from "../../../utils/FieldUtils";
import { Button, Collapse, InputNumber, Space } from "antd";
import L, { FitBoundsOptions, LatLng, Polygon } from "leaflet";
import { area, polygon } from "@turf/turf";
import { bounds, polygons } from "../MapUtils";
import Coordinates from "../../../model/coordinates";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip } from 'antd';
import { useAgroMap } from "../AgroMapProvider";

interface EditGeoFieldMapComponentProps {
    map: L.Map,
    geoField: GeoField,
    onGeoFieldChanged: (geoField: GeoField) => void
}

function convertToGeoField(layer: Polygon<any>, currentGeoField: GeoField) {
    const rawLatLngs = layer.getLatLngs();
    const positions: number[][][] = (rawLatLngs as LatLng[][]).map(boundary =>
        boundary.map(latlng => [latlng.lng, latlng.lat])
    )
    positions.forEach(polygon => {
        polygon.push(polygon[0])
    })
    const turfPolygon = polygon(positions)
    const newBoundaries: Coordinates[][] = (rawLatLngs as LatLng[][]).map(latLngs =>
        latLngs.map(latLng => ({
            latitude: latLng.lat,
            longitude: latLng.lng
        }) as Coordinates)
    );
    // update the polygon and area in GeoField
    return {
        ...currentGeoField,
        boundaries: newBoundaries,
        area: area(turfPolygon),
    };
}

const EditGeoFieldMapComponent: React.FC<EditGeoFieldMapComponentProps> = (props) => {
    const map = props.map
    const {t} = useTranslation()
    const polygonFieldOptions = {
        color: 'gray',
        fillColor: '#3f3f3f',
        fillOpacity: 0.2,
        weight: 2
    }
    const [layer, setLayer] = useState(L.polygon(polygons(props.geoField.boundaries), polygonFieldOptions))

    useEffect(() => {
        const fitBoundsOptions: FitBoundsOptions = {
            padding: [20, 20],
            noMoveStart: false
        }
        map.flyToBounds(bounds(props.geoField.boundaries), fitBoundsOptions)

    }, [props.geoField, map]);


    useEffect(() => {
        props.onGeoFieldChanged(convertToGeoField(layer, props.geoField))
        layer.addTo(map)
        layer.pm.enable({
            allowSelfIntersection: false,
            allowSelfIntersectionEdit: false,
        })
        map.pm.setLang('pl', {
            buttonTitles: {
                editButton: t('edit'),
                cutButton: t('cut')
            },
            actions: {
                finish: t('finish'),
                cancel: t('cancel'),
                removeLastVertex: t('mapFieldRemoveLastVertex'),
            },
            tooltips: {
                firstVertex: t('mapFieldPlaceFirstVertex'),
                continueLine: t('mapFieldPlaceNextVertex'),
                finishPoly: t('mapFieldPlaceFinishVertex'),
            }
        })
        map.pm.addControls({
            drawCircle: false,
            editControls: true,
            drawControls: false,
            rotateMode: false,
            removalMode: false,
            allowSelfIntersection: false,
            allowSelfIntersectionEdit: false,
            cutPolygon: true,
            dragMode: false
        })
        layer.on("pm:markerdragstart", (e: any) => {
            map.scrollWheelZoom.disable()
        })
        layer.on("pm:markerdragend", (e: any) => {
            map.scrollWheelZoom.enable()
        })
        layer.on("pm:change", (e: any) => {
            props.onGeoFieldChanged(convertToGeoField(layer, props.geoField))
        })
        layer.on("pm:edit", (e: any) => {
            props.onGeoFieldChanged(convertToGeoField(layer, props.geoField))
        })
        layer.on("pm:cut", (e: any) => {
            props.onGeoFieldChanged(convertToGeoField(e.layer, props.geoField))
            setLayer(e.layer)
        })

        return () => {
            layer.off()
            map.pm.disableGlobalCutMode()
            map.pm.disableGlobalEditMode()
            map.pm.removeControls()
            map.removeLayer(layer)
        }
    }, [layer, map]);

    return <div></div>
}

interface AddFieldMapViewProps {
    geoField: GeoField
    map: L.Map,
    onAddGeoField?: (geoField: GeoField) => void
    onCanceled?: () => void
}

interface EditAreaViewProps {
    area: number
    areaInHa: boolean
    onAreaClick: () => void
    onChanged: (area: number) => void
}

const EditAreaView: React.FC<EditAreaViewProps> = (props) => {
    const {t} = useTranslation()
    const [isEdit, setEdit] = useState(false)
    const [newArea, setNewArea] = useState<number>(Math.round(props.area))
    const inputRef = useRef(null)
    const onFinish = () => {
        setEdit(false)
        props.onChanged(newArea)
    }

    useEffect(() => {
        setNewArea(Math.round(props.area))
    }, [props.area]);

    // Event handler to check if the click is outside
    const handleClickOutside = (event: any) => {
        // @ts-ignore
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setEdit(false)
            onFinish()
        }
    };

    useEffect(() => {
        if (isEdit) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        // Return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isEdit]);

    return <div
        ref={inputRef}
        style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
        }}
    >
        {isEdit ? <InputNumber
            style={{
                margin: 0,
                fontWeight: "bold",
            }}
            suffix={'m²'}
            className={'custom-input-number'}
            prefixCls={"ant-input-number"}
            size={'small'}
            onPressEnter={onFinish}
            controls={true}
            defaultValue={newArea}
            onChange={(newValue) => {
                if (newValue == null) {
                    setNewArea(props.area)
                } else {
                    setNewArea(newValue as number)
                }
            }}
        /> : <Paragraph
            onClick={props.onAreaClick}
            style={{
                cursor: 'pointer',
                margin: 0,
                fontWeight: "bold"
            }}
        >{getAreaInHa(props.area, props.areaInHa, t)}</Paragraph>}
        <EditOutlined style={{marginLeft: 5}} onClick={() => {
            setEdit(!isEdit)
        }}/>
    </div>
}

const EditGeoFieldDataView: React.FC<AddFieldMapViewProps> = (props) => {
    const [originalArea, setOriginalArea] = useState<number>(props.geoField.area)
    const {t} = useTranslation()
    const [geoField, setGeoField] = useState<GeoField>(props.geoField)
    const [areaInHa, setAreaInHa] = useState<boolean>(true)
    const agroMap = useAgroMap()

    const onCanceled = useCallback((e: React.MouseEvent) => {
        e.stopPropagation()
        agroMap.setEditField(null)
        agroMap.setMode(null)
    }, [])

    const onAdded = (e: React.MouseEvent) => {
        e.stopPropagation()
        props.onAddGeoField && props.onAddGeoField(geoField)
    }

    const onGeoFieldNameChanged = useCallback((value: string) => {
        setGeoField({
            ...geoField,
            fieldName: value
        })
    }, [geoField])

    return <div style={{width: '100%'}}>
        <EditGeoFieldMapComponent
            map={props.map}
            geoField={props.geoField}
            onGeoFieldChanged={setGeoField}
        />

        <div
            style={{
                display: 'flex',
                zIndex: 1000,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>

                <div style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'start',
                    marginBottom: 10,
                }}>
                    <Paragraph
                        style={{margin: 0}}>{t('fieldRegistrationNumber')}</Paragraph>
                    <Paragraph style={{
                        margin: 0,
                        fontWeight: "bold"
                    }}>{geoField.registrationNumber}</Paragraph>

                    <Paragraph
                        style={{margin: 0, marginTop: 10}}>{t('fieldName')}</Paragraph>
                    <Paragraph
                        editable={{onChange: onGeoFieldNameChanged}}
                        style={{
                            margin: 0,
                            fontWeight: "bold"
                        }}>{geoField.fieldName}</Paragraph>

                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        marginTop: 10,
                    }}>

                        <Paragraph style={{margin: 0}}>{t('fieldMapPopupArea')}</Paragraph>
                        <Paragraph
                            onClick={() => setAreaInHa(!areaInHa)}
                            style={{
                                cursor: 'pointer',
                                margin: 0,
                                marginLeft: 5,
                                fontWeight: "bold"
                            }}>{getAreaInHa(originalArea, areaInHa, t)}</Paragraph>
                    </div>
                    {geoField.mkoArea !== null && <div style={{
                        display: 'flex',
                        flexDirection: "row",
                    }}>
                        <Tooltip title={t('mkoExplanation')}>
                            <Paragraph>{t('fieldMapPopupMKOArea')}</Paragraph>
                        </Tooltip>
                        <Paragraph

                            onClick={() => setAreaInHa(!areaInHa)}
                            style={{
                                marginLeft: 5,
                                cursor: 'pointer',
                                fontWeight: "bold"
                            }}>{getAreaInHa(geoField.mkoArea, areaInHa, t)}</Paragraph>
                    </div>
                    }

                    <Paragraph style={{margin: 0}}>{t('selectedArea')}</Paragraph>
                    <EditAreaView
                        area={geoField.area}
                        onAreaClick={() => setAreaInHa(!areaInHa)}
                        onChanged={(newArea) => {
                            setGeoField({
                                ...geoField,
                                area: newArea
                            })
                        }}
                        areaInHa={areaInHa}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        marginTop: 10,
                    }}
                >
                    <Button
                        onClick={onAdded}
                        style={{
                            width: '50%',
                            minWidth: 120,
                            pointerEvents: 'auto',
                            marginRight: 10,
                        }}
                        type={'primary'}
                    >{t('addFieldButton')}</Button>

                    <Button
                        onClick={onCanceled}
                        style={{
                            width: '50%',
                            minWidth: 120,
                            pointerEvents: 'auto',
                        }} danger>{t('cancel')}</Button>
                </div>
            </div>
        </div>
    </div>
}

const EditGeoFieldMapView: React.FC<AddFieldMapViewProps> = (props) => {
    const {t} = useTranslation()

    return <div
        style={{
            pointerEvents: 'auto',
        }}
    >
        <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            style={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomRightRadius: 15,
                borderBottomLeftRadius: 15,
                minWidth: 295,
            }}
        >
            <Collapse.Panel
                style={{padding: 5}}
                header={<Title style={{
                    margin: 0,
                    marginRight: 10,
                }}
                               level={5}
                >{t('fieldEditPopupTitle')}</Title>}
                key={'1'}>
                <EditGeoFieldDataView {...props}/>
            </Collapse.Panel>
        </Collapse>
    </div>
}

export default EditGeoFieldMapView