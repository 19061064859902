import apiClient from "../network_provider";
import GeoPortalMKOArea from "../../model/field/GeoPortalMKOArea";
import MKOParams from "../../model/field/MKOParams";

async function GetMKOFieldAreaProvider(authHeader: string, mkoParams: MKOParams): Promise<number | null> {
    const requestConfig = {
        params: mkoParams
    }
    try {
        const response = await apiClient(authHeader).get<GeoPortalMKOArea>('/geoPortal/mko-area', requestConfig)
        return response.data.mkoArea
    } catch (e) {
        console.log(e)
        return null
    }
}

export default GetMKOFieldAreaProvider
