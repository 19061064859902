import Paragraph from "antd/lib/typography/Paragraph";
import React from "react";

interface TitleItemProps {
    title: string
    label: string
    fontSize?: number
    useMarginTop?: boolean
}

const TitleItemView: React.FC<TitleItemProps> = (props) => {
    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
            width: '100%',
            height: '100%',
            marginTop: (props.useMarginTop || true) ? 10 : 0,
        }}>
        <Paragraph
            style={{
                marginTop: 10,
                margin: 0,

                fontSize: props.fontSize || 14
            }}
        >{props.title}</Paragraph>
        <Paragraph
            style={{
                fontWeight: 'bold',
                margin: 0,
                fontSize: props.fontSize || 14
            }}>
            {props.label}</Paragraph>
    </div>
}

export default TitleItemView
