import React, { createContext, useEffect, useState } from 'react';
import User from '../model/user';
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import UserProvider from "../network/user_provider";
import Farm from "../model/farm/farm";
import GetFarmService from "../network/farm/GetFarmService";

interface SessionAppState {
    user: User | undefined;
    farm: Farm | undefined;
}

interface AppContextProps {
    sessionAppState: SessionAppState
    updateUser: () => void;
    updateFarm: () => void;
    putUser: (user: User) => void;
    putFarm: (farm: Farm | undefined) => void;
}

export const SessionAppContext = createContext<AppContextProps | null>(null);

const SessionAppProvider: React.FC<any> = ({children}) => {
    const authHeader = useAuthHeader()
    const [sessionAppState, setSessionAppState] = useState<SessionAppState>({
        user: undefined,
        farm: undefined,
    });

    useEffect(() => {
        const storedState = sessionStorage.getItem('sessionAppState');
        if (storedState) {
            setSessionAppState(JSON.parse(storedState));
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('sessionAppState', JSON.stringify(sessionAppState));
    }, [sessionAppState]);

    const updateUser = () => {
        UserProvider(authHeader!).then((user) => {
            setSessionAppState((prevState) => ({
                ...prevState,
                user: user,
            }));
        })
    }

    const updateFarm = () => {
        GetFarmService(authHeader!).then((farm) => {
            setSessionAppState((prevState) => ({
                ...prevState,
                farm: farm,
            }));
        })
    }

    const putUser = (user: User) => {
        setSessionAppState((prevState) => ({
            ...prevState,
            user: user,
        }));
    }

    const putFarm = (farm: Farm | undefined) => {
        setSessionAppState((prevState) => ({
            ...prevState,
            farm: farm,
        }));
    }

    return (
        <SessionAppContext.Provider value={{sessionAppState, updateUser, updateFarm, putUser, putFarm}}>
            {children}
        </SessionAppContext.Provider>
    );
}

export default SessionAppProvider;
