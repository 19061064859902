import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import HeaderView from "../dashboard/header/HeaderView";
import React from "react";
import { Content } from "antd/es/layout/layout";
import { gaPageViewTracker } from "../../utils/UseAnalyticsEventTracker";

const {Header: AntHeader} = Layout;

const Onboarding: React.FC = () => {
    gaPageViewTracker('onboarding')
    return (
        <Layout
            style={{
                display: 'flex',
                height: '100vh',
            }}>
            <AntHeader
                style={{
                    display: 'flex',
                    position: 'fixed',
                    height: '60px',
                    width: '100%',
                    padding: 0,
                }}>
                <HeaderView/>
            </AntHeader>
            <Content
                style={{
                    display: 'flex',
                    marginTop: '64px',
                    marginRight: '0px',
                    padding: '24px',
                    borderRadius: '5px',
                    height: 'calc(100vh - 94px)',
                }}>

                <div
                    style={{
                        display: 'flex',
                        background: 'white',
                        height: '100%',
                        width: '100%',
                        margin: '20',
                        padding: '24px',
                        justifyContent: 'center',
                    }}>
                    <Outlet/>
                </div>
            </Content>
        </Layout>
    )
}

export default Onboarding
