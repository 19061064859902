import React, { useEffect, useState } from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import { useTranslation } from "react-i18next";
import { Button, Empty, Input, message, Modal, Select } from "antd";
import Performer from "../../../../model/performer/Performer";
import GetPerformerProvider from "../../../../network/performer/GetPerformerProvider";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import AddPerformerProvider from "../../../../network/performer/AddPerformerProvider";

interface PerformerViewProps {
    onPerformerSelected: (performer: Performer) => void
}

const PerformerView: React.FC<PerformerViewProps> = (props) => {
    const authHeader = useAuthHeader()!
    const [performers, setPerformers] = useState<Performer[]>([])
    const {t} = useTranslation()
    const [porformersOptions, setPerformersOptions] = useState<any[]>([])
    const [isNewPerformerModalVisible, setIsNewPerformerModalVisible] = useState<boolean>(false)
    const [newPerformerName, setNewPerformerName] = useState<string | null>(null)
    const [addPerformerButtonActive, setAddPerformerButtonActive] = useState<boolean>(false)
    const [messageApi, messageContextHolder] = message.useMessage();
    const [defaultOption, setDefaultOption] = useState<any>([])

    const loadPerformers = async () => {
        try {
            const performersResponse = await GetPerformerProvider(authHeader)
            setPerformers(performersResponse)
            setPerformersOptions(performersResponse.map(performer => {
                return {
                    value: performer.id,
                    label: performer.name
                }
            }))
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        loadPerformers()
    }, []);

    const onPerformerSelected = (value: string) => {
        const performer = performers.find(performer => performer.id === value)
        if (performer) {
            props.onPerformerSelected(performer)
        }
    }

    useEffect(() => {
        if (newPerformerName && newPerformerName.length > 2) {
            setAddPerformerButtonActive(true)
        } else {
            setAddPerformerButtonActive(false)
        }
    }, [newPerformerName]);

    const onAddNewPerformer = async () => {
        if (newPerformerName && newPerformerName.length > 2) {
            try {
                await AddPerformerProvider(authHeader, newPerformerName)

                setTimeout(() => {
                    loadPerformers()
                }, 200)
            } catch (e) {
                messageApi.error(t('errorAddingPerformer'))
                console.error(e)
            }
        }
        setIsNewPerformerModalVisible(false)
    }

    useEffect(() => {
        if (newPerformerName) {
            const defaultPerformer = performers.find(performer => performer.name === newPerformerName)
            console.log("DefaultPerformer: ", newPerformerName, defaultPerformer, performers)
            if (!defaultPerformer) return
            setDefaultOption({
                value: defaultPerformer?.id,
                label: defaultPerformer?.name

            })
            setNewPerformerName(null)
        }
    }, [performers]);

    const onCancelNewPerformer = () => {
        setIsNewPerformerModalVisible(false)
    }

    return (<div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
            marginTop: 10,
            width: '100%',
        }}>

            {messageContextHolder}
            <Modal
                centered
                open={isNewPerformerModalVisible}
                onOk={onAddNewPerformer}
                onCancel={onCancelNewPerformer}
                okButtonProps={{disabled: !addPerformerButtonActive}}
                okText={t('save')}
                cancelText={t('cancel')}
                closeIcon={false}>
                <Paragraph
                    style={{whiteSpace: 'pre-line'}}
                >{t('typeInNewPerformerName')}</Paragraph>
                <Input
                    value={newPerformerName || ''}
                    onChange={(e) => setNewPerformerName(e.target.value)}
                >

                </Input>
            </Modal>
            {isNewPerformerModalVisible && <Modal></Modal>}

            <Paragraph style={{margin: 0}}>{t('mapFieldAddPerformer')}</Paragraph>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >

                <Select
                    style={{
                        width: '100%',
                        textAlign: 'start',
                    }}
                    value={defaultOption !== null ? defaultOption.value : undefined}
                    placeholder={t('mapFieldAddPerformer')}
                    options={porformersOptions}
                    onChange={onPerformerSelected}
                    notFoundContent={<div>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={t('mapFieldAddEmptyPerformers')}
                        />
                    </div>}
                    allowClear/>

                <Button
                    style={{marginTop: 10}}
                    onClick={() => setIsNewPerformerModalVisible(true)}>{t('mapFieldAddNewPerformer')}</Button>
            </div>
        </div>
    )
}

export default PerformerView