import { gaPageViewTracker } from "../../utils/UseAnalyticsEventTracker";

const Machines: React.FC = () => {
    gaPageViewTracker('machines')
    return (
        <div>Park maszyn</div>
    )
}

export default Machines
