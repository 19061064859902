import { useAgroMap } from "../AgroMapProvider";
import { Collapse, Switch } from "antd";
import { useTranslation } from "react-i18next";
import Title from "antd/lib/typography/Title";
import React from "react";
import Paragraph from "antd/lib/typography/Paragraph";

const AgroMapUIView: React.FC = () => {
    const {t} = useTranslation()
    const agroMap = useAgroMap()

    return <div
        style={{
            pointerEvents: 'auto',
        }}
    ><Collapse
        bordered={false}
        defaultActiveKey={['1']}
        style={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 15,
            borderBottomLeftRadius: 15,
            minWidth: 200,
        }}>
        <Collapse.Panel
            style={{padding: 5}}
            header={<Title style={{
                margin: 0,
                marginRight: 10,
            }}
                           level={5}
            >{t('mapUiTitle')}</Title>}
            key={'1'}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'start',
                width: '100%',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}>
                    <Paragraph style={{margin: 0}}>{t('mapUIMKOBorder')}</Paragraph>
                    <Switch onChange={(checked) => {
                        agroMap.setMapUI({...agroMap.mapUI, showMKOArea: checked})
                    }}
                            defaultValue={agroMap.mapUI.showMKOArea}/>
                </div>
                <div style={{
                    marginTop: 15,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}>
                    <Paragraph style={{margin: 0}}>{t('mapUISatellite')}</Paragraph>
                    <Switch onChange={(checked) => {
                        agroMap.setMapUI({...agroMap.mapUI, showSatellite: checked})
                    }}
                            defaultValue={agroMap.mapUI.showSatellite}/>
                </div>
            </div>
        </Collapse.Panel>

    </Collapse>
    </div>
}

export default AgroMapUIView;
