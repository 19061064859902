
export function getAreaInHa(area: number, areaInHa: Boolean, t: any): string {
    let areaString = '';
    if (areaInHa) {
        areaString = t('fieldAreaInHa', {area: (area / 10000).toFixed(2)})
    } else {
        areaString = t('fieldAreaInM', {area: area.toFixed(0)})
    }
    return areaString
}

export default getAreaInHa;
