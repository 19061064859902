import Spraying from "../../model/spraying/Spraying";
import apiClient from "../network_provider";
import SprayingFull from "../../model/spraying/SprayingFull";

async function SprayingProvider(authToken: string): Promise<Array<Spraying>> {
    const response = await apiClient(authToken).get<Array<Spraying>>('/sprayings')
    return response.data
}

export async function SprayingsFullProvider(authToken: string): Promise<Array<SprayingFull>> {
    const response = await apiClient(authToken).get<Array<SprayingFull>>('/sprayings-full')
    return response.data
}

export default SprayingProvider
