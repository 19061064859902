import './sidemenu.css'
import React, { useState } from "react";
import { Divider, Menu, MenuProps, Typography } from "antd";
import { useTranslation } from "react-i18next";
import {
    ArrowLeftOutlined,
    HomeOutlined,
    PictureOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Paragraph from "antd/lib/typography/Paragraph";


type MenuItem = Required<MenuProps>['items'][number]

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

interface SideMenuProps {
    showCloseButton: boolean
    onCloseButtonClick: () => void
}

const SideMenuView: React.FC<SideMenuProps> = (props) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const version = process.env.REACT_APP_VERSION;

    function handleClick(e: any) {
        navigate(e.key)
    }

    const menuItems: MenuItem[] = [
        getItem(t('menuDashboard'), '/', <HomeOutlined/>),
        getItem(t('menuYourFields'), '/fields', <PictureOutlined/>),
        getItem(t('menuYourCrops'), '/crops', <PictureOutlined/>),
        getItem(t('menuSprayings'), '/sprayings', <PictureOutlined/>),
        getItem(t('menuFertilizers'), '/fertilizers', <PictureOutlined/>),
    ]

    return (
        <div className={'SideMenu'}>
            <div className={'SideMenu-Content'}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <img style={{
                        marginLeft: '20px',
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}
                         src="/logo.png" alt="AgroArchitect logo" className="logo"/>
                    {props.showCloseButton &&
                        <ArrowLeftOutlined style={{
                            marginTop: '35px',
                            marginRight: '20px',
                        }} onClick={props.onCloseButtonClick}/>
                    }
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'auto',
                    justifyContent: 'space-between',
                }}>

                    <Menu
                        mode="inline"
                        onClick={handleClick}
                        items={menuItems}
                        style={{
                            marginTop: '50px',
                        }}/>

                    <div
                        style={{
                            bottom: '0',
                            width: '100%',
                            padding: '10px',
                            textAlign: 'center',
                            borderTop: '1px solid #f0f0f0',
                        }}>
                        <Paragraph style={{margin: 0}}
                                   type="secondary">{t('menuFooterVersion', {version: version})}</Paragraph>
                        <Paragraph style={{margin: 0}} type="secondary">{t('footer1')}</Paragraph>
                        <Paragraph style={{margin: 0}} type="secondary">{t('footer2')}</Paragraph>
                    </div>
                </div>
            </div>
            <Divider type="vertical" style={{margin: 0, height: '100%'}}/>
        </div>
    )
}

export default SideMenuView
