import React, { useEffect, useRef, useState } from "react";
import Fertilizer from "../../model/fertilizing/Fertilize";
import { Button, Empty, Input, Space, Spin, Table, TableColumnsType } from "antd";
import { useTranslation } from "react-i18next";
import useMessage from "antd/es/message/useMessage";
import { AddFertilizerService, GetFertilizerService } from "../../network/fertilize/GetFertilizerService";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { Size } from "../../utils/UseElementSize";
import useResizeObserver from "@react-hook/resize-observer";

const FertilizersView: React.FC = () => {
    const authHeader = useAuthHeader()!
    const {t} = useTranslation()
    const [fertilizers, setFertilizers] = useState<Fertilizer[]>([])
    const [messageApi, messageContextHolder] = useMessage()
    const [loading, setLoading] = useState<boolean>(false)
    const [isAddingFertilizer, setIsAddingFertilizer] = useState<boolean>(false)
    const parentRef = useRef<HTMLDivElement>(null)
    const [size, setSize] = useState<Size>({width: 0, height: 0})
    const [newFertilizerName, setNewFertilizerName] = useState<string>('')

    const setRoundedSize = ({width, height}: Size) => {
        setSize({width: Math.round(width), height: Math.round(height)});
    };

    useResizeObserver(parentRef, entry => {
        const {inlineSize: width, blockSize: height} = entry.contentBoxSize[0];
        setRoundedSize({width, height});
    });

    const columns: TableColumnsType<Fertilizer> = [
        {
            title: t('fertilizerName'),
            dataIndex: 'name',
            key: 'name',
            width: 200,
        }
    ]

    const loadFertilizers = async () => {
        try {
            setLoading(true)
            const fertilizers = await GetFertilizerService(authHeader)
            setFertilizers(fertilizers)
            setLoading(false)
        } catch (e) {
            console.log(e)
            messageApi.error(t('fertilizerErrorLoading'))
            setLoading(false)
        }
    }

    const addFertilizer = async () => {
        try {
            setIsAddingFertilizer(true)
            await AddFertilizerService(authHeader, newFertilizerName)
            const fertilizes = await GetFertilizerService(authHeader)
            setFertilizers(fertilizes)
            setIsAddingFertilizer(false)
        } catch (e) {
            messageApi.error(t('fertilizerErrorAdding'))
            setIsAddingFertilizer(false)
            console.error(e)
        }
    }

    useEffect(() => {
        loadFertilizers()
    }, []);

    const header = <Space.Compact style={{width: 500}}>
        <Input
            allowClear
            placeholder={"Dodaj nawóz"}
            onChange={(e) => setNewFertilizerName(e.target.value)}
        />
        <Button
            type="primary"
            // className={'Button-Start'}
            onClick={addFertilizer}
            disabled={newFertilizerName.length <= 2}
            loading={isAddingFertilizer}
        >
            {t('fertilizerAdd')}
        </Button>
    </Space.Compact>

    return <div
        style={{
            display: 'flex',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
        }}
        ref={parentRef}
    >
        {messageContextHolder}
        {loading && <div><Spin style={{width: 300}}/></div>}
        <Table
            style={{
                overflow: 'auto',
                height: '100%',
                width: '100%'
            }}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t('fertilizerEmpty')}
                    />
                ),
            }}
            scroll={
                {
                    y: size.height
                }
            }
            showHeader={true}
            title={() => header}
            bordered={true}
            dataSource={fertilizers}
            columns={columns}
            pagination={false}
        />
    </div>
}

export default FertilizersView