import apiClient from "../network_provider";

async function AddPerformerProvider(authHeader: string, performerName: string): Promise<void> {
    const params = {
        name: performerName
    }
    await apiClient(authHeader).put('/performer', params)
}

export default AddPerformerProvider
