import Coordinates from "../../model/coordinates";
import StaticMapRequest from "../../model/map/StaticMapRequest";

export function getStaticMapParams(boundary: Coordinates[]): StaticMapRequest {
    return {
        boundaries: boundary,
        width: 250,
        height: 250,
        polyline: {
            strokeWidth: 1,
            strokeOpacity: 1,
            fillOpacity: 0.2,
        }
    }
}
