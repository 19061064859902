import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import i18 from "i18next";
import global_pl from "../locales/pl/global.json"

i18
    .use(initReactI18next)
    .init({
        lng: 'pl',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            pl: {
                translation: global_pl
            },
        },
    })

export default i18n
